

























import {
  createComponent,
  computed,
  inject,
  onMounted,
  reactive,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import router from "@/router";
import PageTitle from "@/components/atoms/text/PageTitle.vue";
import Footer from "@/components/molecules/Footer.vue";

export default createComponent({
  setup(props, context) {
    const state = reactive({});

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    onMounted(async () => {
      displayui.closeDialogEncourageRegistration();
      displayui.leaveHeaderMyPage();

      const notice_id = String(context.root.$route.query.notice_id);
      await user.requestNoticeItem(notice_id);
    });

    const notice_item = computed(() => user.notice_item);

    const formatDate = function (dateStr: string, isTime: boolean) {
      const date = new Date(dateStr);
      const str_year = date.getFullYear();
      const str_month = ("0" + (date.getMonth() + 1)).slice(-2);
      const str_date = ("0" + date.getDate()).slice(-2);

      if (isTime) {
        const str_hours = ("0" + date.getHours()).slice(-2);
        const str_minutes = ("0" + date.getMinutes()).slice(-2);
        return `${str_year}/${str_month}/${str_date} ${str_hours}:${str_minutes}`;
      } else {
        return `${str_year}/${str_month}/${str_date}`;
      }
    };

    return {
      formatDate,
      notice_item,
    };
  },

  components: {
    PageTitle,
    Footer,
  },
});
