


























































































import {
  createComponent,
  inject,
  onMounted,
  computed,
  reactive,
  SetupContext,
  getCurrentInstance,
} from "@vue/composition-api";
import RegistrationKey from "@/components/registration-key";
import DisplayUIKey from "@/components/display-ui-key";
import router from "@/router";

export default createComponent({
  setup(context: SetupContext) {
    const registration = inject(RegistrationKey);
    if (!registration) {
      throw new Error(`${RegistrationKey} is not provided`);
    }

    const instance = getCurrentInstance();

    const state = reactive({
      email: instance?.$route.query ? instance?.$route.query.email.toString() : "",
      authcode: "",
    });

    registration.clearAuthCode();
    registration.clearResponse();
    registration.inputMail(state.email);
    const response = computed(() => registration.response);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayui: any = inject(DisplayUIKey);
    const leaveDrawer = displayui.leaveDrawer;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onMounted(() => {
      leaveDrawer();
      leaveHeaderSub();
      leaveHeaderMyPage();
    });

    //メアド、認証コード入力欄チェック
    const checkAuthcode = computed(
      () => state.authcode.match(authcodeRegex) && state.email.match(mailRegex)
    );

    // メアドが入力されているか判定
    const inputMail = () => {
      registration.inputMail(state.email);
      return state.email !== "";
    };

    // 認証コードが入力されたとき以下を判定
    // (入力されているか、半角数字以外が入力されたら消す、6桁以上は入力させない)
    const inputAuthcode = () => {
      state.authcode = state.authcode.replace(/[^0-9]+/i, "");
      if (state.authcode.length > 6)
        state.authcode = state.authcode.slice(0, 6);
      registration.inputAuthCode(state.authcode);
    };
    // 認証コード・メールアドレスの組み合わせ検証 & 会員情報入力画面遷移
    const postAuthcode = async () => {
      const entryPinVerifyStatus = await registration.postVerify();
      // 呼び出し失敗
      if (entryPinVerifyStatus >= 400) {
        // メッセージ表示
        alert(registration.message);
        registration.clearMessage();

        // リダイレクト設定がある場合は実行
        if(registration.redirectTo){
          const redirectTo = registration.redirectTo;
          registration.clearRedirectTo();
          router.replace(redirectTo);
        }
        return;
      }
      // 会員情報入力画面へ遷移
      router.push({
        name: "RegistrationInputPersonalInfo",
      });
    };

    // メアドチェック用正規表現
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // 認証コードチェック用正規表現
    const authcodeRegex = /^[0-9]{6,}$/;

    return {
      state,
      checkAuthcode,
      inputMail,
      inputAuthcode,
      postAuthcode,
    };
  },
});
