








import { createComponent } from "@vue/composition-api";

import SearchSvg from "@/assets/svg/search_base.svg";

export default createComponent({
  components: {
    SearchSvg,
  },
});
