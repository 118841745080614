



















































































































































import {
  createComponent,
  inject,
  computed,
} from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import ProvisionalKey from "@/components/provisional-key";
import RegistrationKey from "@/components/registration-key";
import UserKey from "@/components/user-key";
// import _ from "lodash";
import router from "@/router";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const provisionalStore = inject(ProvisionalKey);
    if (!provisionalStore) {
      throw new Error(`${ProvisionalKey} is not provided`);
    }
    const registration = inject(RegistrationKey);
    if (!registration) {
      throw new Error(`${RegistrationKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    // 画面リフレッシュされていたら登録トップページへ遷移
    if (registration.mail === "") {
      router.replace("/member");
    }
    // 画面編集中に設定
    displayui.setEditing(true);

    const response = computed(() => registration.response);

    const displayParams = computed(() => registration.displayParams);
    const displayError = computed(() => registration.displayError);
    const displayErrorFlag = computed(() => registration.displayErrorFlag);
    const imageSrcList = computed(() => {
      return registration.imageSrcList.filter((src: string) => {
        return src !== "";
      });
    });
    const imageOtherSrcList = computed(() => {
      return registration.imageOtherSrcList.filter((src: string) => {
        return src !== "";
      });
    });
    const mail = computed(() => registration.mail);

    // 選択性別名称取得
    const selectedSex = computed(() => {
      const selectedSexResult = registration.sexList.find(
        (el) => el.code === displayParams.value.sex
      );
      return selectedSexResult ?? '';
    });
    // 選択国籍名称取得
    const selectedNationality = computed(() => {
      const selectedNationalityResult = registration.nationalityList.find(
        (el) => el.code === displayParams.value.nationality
      );
      return selectedNationalityResult ?? '';
    });
    // 選択書類名称取得
    const selectedIdentify = computed(() => {
      const selectedIdentifyResult = registration.identifyList.find(
        (el) => el.code === displayParams.value.document
      );
      return selectedIdentifyResult ?? '';
    }) 

    // エントリー更新API・エントリー確定API・サンキューページへの遷移
    const submitAction = async () => {
      const entryUpdateResultStatus = await registration.postEntryUpdate();
      // エントリー失敗
      if (entryUpdateResultStatus !== 201) {
        // エントリー更新APIのエラーメッセージ表示
        alert(registration.message);
        registration.clearMessage();
        return;
      }
      const isVpTermsAgree = provisionalStore.isVpTermsAgree;
      const entryConfirmResultStatus = await registration.postEntryConfirm(isVpTermsAgree);
      // エントリー失敗
      if (entryConfirmResultStatus !== 201) {
        // エントリー確定APIのエラーメッセージ表示
        alert(registration.message);
        registration.clearMessage();
        return;
      }

      // 画面編集中解除
      displayui.setEditing(false);
      
      // 2つのAPIが両方成功したらサンキューページへ遷移
      router.push({
        name: "RegistrationThankyouPage"
      });
    };

    // onMounted(async () => {
    //   if (!token) {
    //     // 登録済データから復帰
    //     await registration.getJudgementMember(judgementMemberId);
    //     registration.loadParams();
    //   }
    // });

    return {
      mail,
      selectedIdentify,
      selectedSex,
      selectedNationality,
      displayParams,
      imageSrcList,
      imageOtherSrcList,
      displayError,
      displayErrorFlag,
      submitAction,
    };
  },
});
