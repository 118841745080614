// これはグローバルなフィルタです。
// main.tsにて読み込んで、mixinしてあります。numa
// import moment from "moment";
// moment.locale("ja");

export default {
  testFilter(value: any) {
    return "OK" + value; //テストFilter
  },
  onlyHalfNumber(value: string) {
    const result = value.replace(/[０-９]/g, function (string) {
      return String.fromCharCode(string.charCodeAt(0) - 65248);
    });
    return result.replace(/\D/g, "");
  },
  monthNumber(datedata: Date) {
    return datedata.getMonth(); //月を取得
  },
  // formatDate(date: any) {
  //   let momentObject = moment(date);
  //   if (!momentObject.isValid()) return "";
  //   return momentObject.format("YYYY-MM-DD");
  // },
  moneyDelemiter(value: number) {
    return Number(value).toLocaleString(); //3桁区切りでカンマをつける
  },
  capitalize(value: any) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1); //キャピタライズ
  },
  slicehead(word: any, n: number) {
    return word.slice(0, n); //あたまからn文字数表示（ブロックからはみ出る場合）
  },
  sliceheadFive(word: any) {
    return word.slice(0, 5); //あたまから5文字数表示
  },
  roundNumber(num: number) {
    return num.toString().indexOf(".") !== -1
      ? `${parseFloat(num.toFixed(1))}  `
      : `${num} `;
  },
  toFixedNumber(num: number) {
    return num.toFixed(1);
  },
  zenkakuToHankaku(beforetext: string) {
    const zen = [
      "ア",
      "イ",
      "ウ",
      "エ",
      "オ",
      "カ",
      "キ",
      "ク",
      "ケ",
      "コ",
      "サ",
      "シ",
      "ス",
      "セ",
      "ソ",
      "タ",
      "チ",
      "ツ",
      "テ",
      "ト",
      "ナ",
      "ニ",
      "ヌ",
      "ネ",
      "ノ",
      "ハ",
      "ヒ",
      "フ",
      "ヘ",
      "ホ",
      "マ",
      "ミ",
      "ム",
      "メ",
      "モ",
      "ヤ",
      "ヰ",
      "ユ",
      "ヱ",
      "ヨ",
      "ラ",
      "リ",
      "ル",
      "レ",
      "ロ",
      "ワ",
      "ヲ",
      "ン",
      "ガ",
      "ギ",
      "グ",
      "ゲ",
      "ゴ",
      "ザ",
      "ジ",
      "ズ",
      "ゼ",
      "ゾ",
      "ダ",
      "ヂ",
      "ヅ",
      "デ",
      "ド",
      "バ",
      "ビ",
      "ブ",
      "ベ",
      "ボ",
      "パ",
      "ピ",
      "プ",
      "ペ",
      "ポ",
      "ァ",
      "ィ",
      "ゥ",
      "ェ",
      "ォ",
      "ャ",
      "ュ",
      "ョ",
      "ッ",
      "゛",
      "°",
      "、",
      "。",
      "「",
      "」",
      "ー",
      "・",
      "　",
    ];

    const han = [
      "ｱ",
      "ｲ",
      "ｳ",
      "ｴ",
      "ｵ",
      "ｶ",
      "ｷ",
      "ｸ",
      "ｹ",
      "ｺ",
      "ｻ",
      "ｼ",
      "ｽ",
      "ｾ",
      "ｿ",
      "ﾀ",
      "ﾁ",
      "ﾂ",
      "ﾃ",
      "ﾄ",
      "ﾅ",
      "ﾆ",
      "ﾇ",
      "ﾈ",
      "ﾉ",
      "ﾊ",
      "ﾋ",
      "ﾌ",
      "ﾍ",
      "ﾎ",
      "ﾏ",
      "ﾐ",
      "ﾑ",
      "ﾒ",
      "ﾓ",
      "ﾔ",
      "ｲ",
      "ﾕ",
      "ｴ",
      "ﾖ",
      "ﾗ",
      "ﾘ",
      "ﾙ",
      "ﾚ",
      "ﾛ",
      "ﾜ",
      "ｦ",
      "ﾝ",
      "ｶﾞ",
      "ｷﾞ",
      "ｸﾞ",
      "ｹﾞ",
      "ｺﾞ",
      "ｻﾞ",
      "ｼﾞ",
      "ｽﾞ",
      "ｾﾞ",
      "ｿﾞ",
      "ﾀﾞ",
      "ﾁﾞ",
      "ﾂﾞ",
      "ﾃﾞ",
      "ﾄﾞ",
      "ﾊﾞ",
      "ﾋﾞ",
      "ﾌﾞ",
      "ﾍﾞ",
      "ﾎﾞ",
      "ﾊﾟ",
      "ﾋﾟ",
      "ﾌﾟ",
      "ﾍﾟ",
      "ﾎﾟ",
      "ｧ",
      "ｨ",
      "ｩ",
      "ｪ",
      "ｫ",
      "ｬ",
      "ｭ",
      "ｮ",
      "ｯ",
      "",
      "",
      "､",
      "｡",
      "｢",
      "｣",
      "ｰ",
      "･",
      " ",
    ];

    let aftertext = "";

    for (let i = 0; i < beforetext.length; i++) {
      let beforechar = beforetext.charAt(i);
      const zenindex = zen.indexOf(beforechar);
      if (zenindex >= 0) {
        beforechar = han[zenindex];
      }
      aftertext += beforechar;
    }

    return aftertext;
  },
  HankakuTozenkaku(beforetext: string) {
    const han = [
      "ｱ",
      "ｲ",
      "ｳ",
      "ｴ",
      "ｵ",
      "ｶ",
      "ｷ",
      "ｸ",
      "ｹ",
      "ｺ",
      "ｻ",
      "ｼ",
      "ｽ",
      "ｾ",
      "ｿ",
      "ﾀ",
      "ﾁ",
      "ﾂ",
      "ﾃ",
      "ﾄ",
      "ﾅ",
      "ﾆ",
      "ﾇ",
      "ﾈ",
      "ﾉ",
      "ﾊ",
      "ﾋ",
      "ﾌ",
      "ﾍ",
      "ﾎ",
      "ﾏ",
      "ﾐ",
      "ﾑ",
      "ﾒ",
      "ﾓ",
      "ﾔ",
      "ｲ",
      "ﾕ",
      "ｴ",
      "ﾖ",
      "ﾗ",
      "ﾘ",
      "ﾙ",
      "ﾚ",
      "ﾛ",
      "ﾜ",
      "ｦ",
      "ﾝ",
      "ｶﾞ",
      "ｷﾞ",
      "ｸﾞ",
      "ｹﾞ",
      "ｺﾞ",
      "ｻﾞ",
      "ｼﾞ",
      "ｽﾞ",
      "ｾﾞ",
      "ｿﾞ",
      "ﾀﾞ",
      "ﾁﾞ",
      "ﾂﾞ",
      "ﾃﾞ",
      "ﾄﾞ",
      "ﾊﾞ",
      "ﾋﾞ",
      "ﾌﾞ",
      "ﾍﾞ",
      "ﾎﾞ",
      "ﾊﾟ",
      "ﾋﾟ",
      "ﾌﾟ",
      "ﾍﾟ",
      "ﾎﾟ",
      "ｧ",
      "ｨ",
      "ｩ",
      "ｪ",
      "ｫ",
      "ｬ",
      "ｭ",
      "ｮ",
      "ｯ",
      "",
      "",
      "､",
      "｡",
      "｢",
      "｣",
      "ｰ",
      "･",
      " ",
    ];
    const zen = [
      "ア",
      "イ",
      "ウ",
      "エ",
      "オ",
      "カ",
      "キ",
      "ク",
      "ケ",
      "コ",
      "サ",
      "シ",
      "ス",
      "セ",
      "ソ",
      "タ",
      "チ",
      "ツ",
      "テ",
      "ト",
      "ナ",
      "ニ",
      "ヌ",
      "ネ",
      "ノ",
      "ハ",
      "ヒ",
      "フ",
      "ヘ",
      "ホ",
      "マ",
      "ミ",
      "ム",
      "メ",
      "モ",
      "ヤ",
      "ヰ",
      "ユ",
      "ヱ",
      "ヨ",
      "ラ",
      "リ",
      "ル",
      "レ",
      "ロ",
      "ワ",
      "ヲ",
      "ン",
      "ガ",
      "ギ",
      "グ",
      "ゲ",
      "ゴ",
      "ザ",
      "ジ",
      "ズ",
      "ゼ",
      "ゾ",
      "ダ",
      "ヂ",
      "ヅ",
      "デ",
      "ド",
      "バ",
      "ビ",
      "ブ",
      "ベ",
      "ボ",
      "パ",
      "ピ",
      "プ",
      "ペ",
      "ポ",
      "ァ",
      "ィ",
      "ゥ",
      "ェ",
      "ォ",
      "ャ",
      "ュ",
      "ョ",
      "ッ",
      "゛",
      "°",
      "、",
      "。",
      "「",
      "」",
      "ー",
      "・",
      "　",
    ];

    let aftertext = "";

    for (let i = 0; i < beforetext.length; i++) {
      let beforechar = beforetext.charAt(i);
      const hanindex = han.indexOf(beforechar);
      if (hanindex >= 0) {
        beforechar = zen[hanindex];
      }
      aftertext += beforechar;
    }

    return aftertext;
  },
  hiraToKana(str: string) {
    return str.replace(/[\u3041-\u3096]/g, function (match: string) {
      const chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  },
  // 「メールアドレス形式」での項目チェック
  checkMailAddressFormat(value: any) {
    if(value == null) return false;
    if(value == '') return false;
    if(value.length > 100) return false;
    
    const regex = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/)
    if(!regex.test(value)) {
      return false;
    }

    return true;
  },
  requiredPassword(value: any) {
    const error_result:any = {
      "message": "半角英数字・記号(! @ # $ % ^ & * + - . / = ? _)で8文字以上入力してください。"
    };

    if(value == null) return error_result;
    if(value == '') return error_result;
    if(value.length > 20) return error_result;
    if(value.length < 8) return error_result;

    const regex = new RegExp(/^[a-zA-Z\d!@#$%^&*+-.\/=?_]*$/)
    if(!regex.test(value)) {
      return error_result;
    }

    return null;
  },
  passwordFormat(value: any) {
    const error_result:any = {
      "message": "半角英字・数字をそれぞれ1文字以上含むように8文字以上入力してください。"
    };

    if(value == null) return error_result;
    if(value == '') return error_result;
    if(value.length > 20) return error_result;
    if(value.length < 8) return error_result;

    const regex = new RegExp(/^(?=.*?[a-zA-Z])(?=.*?\d).*$/)
    if(!regex.test(value)) {
      return error_result;
    }

    return null;
  },
};
