








import { createComponent } from "@vue/composition-api";
import CloseIcon from "@/components/atoms/icon/CloseIcon.vue";

export default createComponent({
  components: {
    CloseIcon,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setup() {},
});
