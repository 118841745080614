


































































































































import {
  createComponent,
  computed,
  inject,
  onMounted,
  reactive,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import router from "@/router";
import PageTitle from "@/components/atoms/text/PageTitle.vue";
import Footer from "@/components/molecules/Footer.vue";
import CommonConfirmDialog from "@/components/templates/CommonConfirmDialog.vue";

export default createComponent({
  setup(props, context) {
    const state = reactive({});

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    onMounted(async () => {
      displayui.closeDialogEncourageRegistration();
      displayui.leaveHeaderMyPage();

      displayui.leaveDrawer();
      displayui.leaveHeaderSub();

      let page_no = 1;
      if (context.root.$route.query.page_no) {
        page_no = Number(context.root.$route.query.page_no);
      }
      await user.requestNoticeList(page_no);
      if (page_no > 1 && user.notice_list.length == 0) {
        router.push("/notice-list");
        return;
      }
    });

    const notice_list = computed(() => user.notice_list);
    const notice_page_no = computed(() => user.notice_page_no);
    const notice_page_max_no = computed(() => user.notice_page_max_no);

    // 全て既読確認
    const confirmNoticeReadAll = () => {
      displayui.openDialogCommon("通知を全て既読にします。よろしいですか？");
    };
    // 全て既読実行
    const setNoticeReadAll = async () => {
      await user.setNoticeReadAll();
    };

    // 通知詳細へ画面遷移
    const gotoNoticeItemPage = async (page_no: string) => {
      router.push({ path: "/notice-list", query: { page_no: page_no } });
      await user.requestNoticeList(Number(page_no));
    };

    const formatDate = function (dateStr: string, isTime: boolean) {
      const date = new Date(dateStr);
      const str_year = date.getFullYear();
      const str_month = ("0" + (date.getMonth() + 1)).slice(-2);
      const str_date = ("0" + date.getDate()).slice(-2);

      if (isTime) {
        const str_hours = ("0" + date.getHours()).slice(-2);
        const str_minutes = ("0" + date.getMinutes()).slice(-2);
        return `${str_year}/${str_month}/${str_date} ${str_hours}:${str_minutes}`;
      } else {
        return `${str_year}/${str_month}/${str_date}`;
      }
    };

    return {
      formatDate,
      notice_list,
      notice_page_no,
      notice_page_max_no,
      confirmNoticeReadAll,
      setNoticeReadAll,
      gotoNoticeItemPage,
    };
  },

  components: {
    PageTitle,
    Footer,
    CommonConfirmDialog,
  },
});
