






import {
  createComponent,
  inject,
  onMounted,
  computed,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import ApplicationHistoryCalendar from "@/components/molecules/ApplicationHistoryCalendar.vue";
// import PageTitle from "@/components/atoms/text/PageTitle.vue";
// import ItemLabel from "@/components/atoms/text/ItemLabel.vue";
// import SubTitle from "@/components/atoms/text/SubTitle.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const leaveSearching = displayui.leaveSearching;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveDrawer = displayui.leaveDrawer;

    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onMounted(async () => {
      leaveSearching();
      leaveHeaderSub();
      leaveHeaderMyPage();
      leaveDrawer();
    });

    return {  };
  },
  components: {
    ApplicationHistoryCalendar,
  },
});
