

























import {
  createComponent,
  onMounted,
  inject,
  computed,
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import ListView from "@/components/templates/ListView.vue";
import Top from "@/views/Top.vue";
import ReqiredLogin from "@/components/templates/RequiredLogin.vue";

export default createComponent({
  setup() {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const onHeaderSub = displayui.onHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;
    const toggleSearching = displayui.toggleSearching;
    const joblist = computed(() => job.joblist);

    onMounted(() => {
      onHeaderSub();
      leaveHeaderMyPage();
    });

    return {
      joblist,
      toggleSearching,
    };
  },

  components: {
    ListView,
    ReqiredLogin,
    Top
  },
});
