







import { createComponent } from "@vue/composition-api";

export default createComponent({
  props: {
    work_start_date_month: {
      type: String,
      default: "9",
    },
    work_start_date_day: {
      type: String,
      default: "23",
    },
  },
  components: {},
});
