import { reactive } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";

interface Response {
  status: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: Object;
}

export default function provisionalStore() {
  const state = reactive({
    loading: false,
    loadingCount: 0,
    response: <Response>{
      status: 0,
    },
    headers: <any>{
      "content-type": "application/json",
    },
    isVpTermsAgree: false,
    email: "",
    message: "",
    entryEmailInfo: {} as any
  });
  const startLoading = () => {
    state.loadingCount++;
    state.loading = state.loadingCount > 0;
  };
  const stopLoading = () => {
    state.loadingCount--;
    state.loading = state.loadingCount > 0;
  };
  const CancelToken = axios.CancelToken;
  const axiosSource = <any>{};
  // バリプラ会員API呼び出し
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosInstance.defaults.timeout = 30000;
  axiosInstance.interceptors.response.use(
    function (response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function (error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  return {
    get loading(): boolean {
      return state.loading;
    },
    get response() {
      return state.response;
    },
    get email() {
      return state.email;
    },
    get message() {
      return state.message;
    },
    setAuthorizationHeader(token: string) {
      state.headers.Authorization = `Bearer ${token}`;
    },

    // ------------------
    // JOBPAY登録用
    // ------------------
    postEntryStartForJobpay() {
      // 会員登録新API用--メール送信API
      startLoading();

      return axiosInstance
        .post(
          "entry/jobpay/start",
          {
            mail_address: state.email,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then(response => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },


    get entryEmailInfo() {
      return state.entryEmailInfo;
    },
    postEntryStart() {
      // 会員登録新API用--メール送信API
      startLoading();

      return axiosInstance
        .post(
          "entry/registration/start",
          {
            mail_address: state.email,
            entry_route_type: "target",
            company_code: "0000023063",
          },
          {
            headers: state.headers,
          }
        )
        .then(response => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    // 【会員登録時】VP規約同意を求めるかどうか
    get isVpTermsAgreeRequired() {
      return true;
    },
    // 【会員登録時】VP規約同意
    clearVpTermsAgree() {
      state.isVpTermsAgree = false;
    },
    // 【会員登録時】VP規約同意
    setVpTermsAgree() {
      state.isVpTermsAgree = true;
    },
    // 【会員登録時】VP規約同意状態取得
    get isVpTermsAgree() {
      return state.isVpTermsAgree;
    },

    inputMail(e: string) {
      state.email = e;
    },
    clearResponse() {
      state.response = {
        status: 0,
      };
    },
    clearMail() {
      state.email = "";
    },
    clearMessage() {
      state.message = "";
    },
  };
}

export type ProvisionalStore = ReturnType<typeof provisionalStore>;
