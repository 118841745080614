




























































import {
  createComponent,
  computed,
  inject,
reactive,
watch,
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SearchHeaderText from "@/components/atoms/text/SearchHeaderText.vue";
import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";
import Footer from "@/components/molecules/Footer.vue";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    interface State {
      featureCode: string[]
    }
    const state = reactive<State>({
      featureCode: []
    });

    const featureCode = computed(() => search.featureCode);

    const featurelist = computed(() => search.featurelist);
    const changeSearchComponent = displayui.changeSearchComponent;

    const decideFeature = (code: string, name: string) => {
      search.decideFeature(code, name);
    };
    const decideNotSelectFeature = () => {
      search.decideNotSelectFeature();
      changeSearchComponent("SearchInputBoxMain");
    };
    watch(featureCode, () => {
      // 検索条件呼び出し時
      state.featureCode = search.featureCode;
    });

    return {
      search,
      featureCode,
      featurelist,
      decideFeature,
      decideNotSelectFeature,
      changeSearchComponent,
      state
    };
  },

  components: {
    Button,
    SearchHeaderText,
    Footer,
  },
});
