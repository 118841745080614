



import { createComponent } from "@vue/composition-api";

import FavoriteSvg from "@/assets/svg/favorite.svg";

export default createComponent({
  components: {
    FavoriteSvg,
  },
});
