

























import { createComponent, computed, inject } from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SearchHeaderText from "@/components/atoms/text/SearchHeaderText.vue";
import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const salarylist = search.salarylist;
    const salary = computed(() => search.salary);
    const decideSalary = (salary: string) => {
      search.decideSalary(salary);
      displayui.changeSearchComponent("SearchInputBoxMain");
    };

    return { salary, salarylist, decideSalary };
  },

  components: {
    Button,
    SearchHeaderText,
  },
});
