














import {
  createComponent,
  inject,
  computed,
  onMounted,
} from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";

import Button from "@/components/atoms/button/Button.vue";
import CloseBtn from "@/components/atoms/button/CloseBtn.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const userAlert = computed(() => user.userAlert);
    const resetUserAlert = user.resetUserAlert;

    onMounted(() => {
      setTimeout(resetUserAlert, 3000);
    });

    return {
      userAlert,
      resetUserAlert,
    };
  },

  components: {
    Button,
    CloseBtn,
  },
});
