






import { createComponent, onMounted, inject } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import JobKey from "@/components/job-key";

import ListView from "@/components/templates/ListView.vue";
// import Button from "@/components/atoms/button/Button.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }

    const onHeaderSub = displayui.onHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;
    const jobjson = job.jobjson;
    onMounted(() => {
      jobjson();
      onHeaderSub();
      leaveHeaderMyPage();
    });
  },

  components: {
    ListView,
  },
});
