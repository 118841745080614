












































































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import UserKey from "@/components/user-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SelectBankHeaderText from "@/components/atoms/text/SelectBankHeaderText.vue";
import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";

export default createComponent({
  setup() {
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const akasatana = user.akasatana;
    const akasatanaDetail = computed(() => user.akasatanaDetail);
    const bankbranchlist = computed(() => user.bankbranchlist);
    const bankjson = user.bankjson;
    const refinedbranchlist = computed(() => user.refinedbranchlist);
    const bank = computed(() => user.bank);

    onMounted(() => {
      bankjson();
      user.setAkasatanaDetail([]);
    });

    const decideBankBranch = (code: string, name: string) => {
      user.decideBankBranch(code, name);
      displayui.changeBankComponent("Bank");
    };
    const decideBranchFirstLetter = (code: string) => {
      user.decideBranchFirstLetter(code);
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const setAkasatanaDetail = (list: [{}]) => {
      user.setAkasatanaDetail(list);
    };

    return {
      akasatana,
      akasatanaDetail,
      bankbranchlist,
      refinedbranchlist,
      bankjson,
      decideBankBranch,
      decideBranchFirstLetter,
      setAkasatanaDetail,
      bank,
    };
  },

  components: {
    Button,
    SelectBankHeaderText,
  },
});
