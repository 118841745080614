



import { createComponent } from "@vue/composition-api";

import CloseSvg from "@/assets/svg/close.svg";

export default createComponent({
  components: {
    CloseSvg,
  },
});
