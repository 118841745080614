import { reactive } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";
import Filters from "@/lib/filters";
import _ from "lodash";

export default function closeAccountStore() {
  const state = reactive({
    loading: false,
    loadingCount: 0,
    response: <any>{
      status: 0,
    },
    headers: <any>{
      "content-type": "application/json",
    },
    formHeaders: <any>{
      "content-type": "multipart/form-data",
    },
    closeReasonSelection: [
      '時間的に勤務が難しくなった',
      '自分に合う求人情報がなかった',
      '使いづらい点があった',
      'その他',
    ],
    displayParams: {
      selectedCloseReason: [],
      close_reason: ""
    },
    displayError: {
      close_reason: ""
    },
    displayErrorFlag: false,
    submitFlag: false,
    message: "",
  });
  const startLoading = () => {
    state.loadingCount++;
    state.loading = state.loadingCount > 0;
  };
  const stopLoading = () => {
    state.loadingCount--;
    state.loading = state.loadingCount > 0;
    state.loading = false
  };
  const CancelToken = axios.CancelToken;
  const axiosSource = <any>{};

  const axiosinstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosinstance.defaults.timeout = 30000;
  axiosinstance.interceptors.response.use(
    function (response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function (error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_SERVER}`
  });
  axiosInstance.defaults.timeout = 30000;
  axiosInstance.interceptors.response.use(
    function(response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function(error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  return {
    get loading(): boolean {
      return state.loading;
    },
    get response() {
      return state.response;
    },
    get closeReasonSelection() {
      return state.closeReasonSelection;
    },
    get displayParams() {
      return state.displayParams;
    },
    get displayError() {
      return state.displayError;
    },
    get displayErrorFlag() {
      return state.displayErrorFlag;
    },
    get message(): string {
      return state.message;
    },
    setAuthorizationHeader(token: string) {
      state.headers.Authorization = `Bearer ${token}`;
      state.formHeaders.Authorization = `Bearer ${token}`;
    },

    // 既存validateDisplayParamsを修正、入力欄判別によって特定のメッセージだけ表示
    validateDisplayParams(category: string) {
      if (category === "close_reason") {
        if(state.displayParams.close_reason === ""){
          state.displayError.close_reason = "退会理由を選択してください";
          state.displayErrorFlag = true;
        }
      }
    },

    //  既存input~を1つに、引数によってどの入力欄か判別、stateに値を渡す
    inputValue(value: string, category: string) {
      if (category === "close_reason") {
        state.displayParams.close_reason = value;
      }
    },

    clearResponse() {
      state.response = {
        status: 0,
      };
    },
    clearDisplayParams() {
      state.displayParams = {
        selectedCloseReason: [],
        close_reason: ""
      };
    },
    
    //  既存clearDisplayError修正。入力欄判別、入力されたらエラー解除する
    clearDisplayError(category: string) {
      state.displayErrorFlag = false;
      if (category === "close_reason" && state.displayParams.close_reason !== "") {
        state.displayError.close_reason = "";
      }
    },
    clearMessage() {
      state.message = "";
    },

    // 退会API呼び出し
    async postCloseAccount() {
      state.loading = true;

      const params = {
        close_reason: state.displayParams.close_reason
      };

      return await axiosinstance
        .post("member/close-account", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // レスポンスstatusが400以下成功、messageは空で渡す
          if (response.status < 400) {
            state.message = "";
            return response.status;
          } else {
            // response.dataにmesseagesがあればそれをstate.messageに入れる
            if (response.data.messages !== "") {
              state.message = response.data.messages;
            } else {
              // messeageがない時は下記をstate.messageに入れる
              state.message = "サーバーでエラーが発生しました";
            }
          }
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => {state.loading = false});
    },
  };
}

export type CloseAccountStore = ReturnType<typeof closeAccountStore>;
