





import { createComponent, inject, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import SearchKey from "@/components/search-key";
import SearchInputBoxMain from "@/components/molecules/SearchInputBoxMain.vue";
import SelectDailySalary from "@/components/molecules/SelectDailySalary.vue";
import SelectLocationPrefecture from "@/components/molecules/SelectLocationPrefecture.vue";
import SelectStartTime from "@/components/molecules/SelectStartTime.vue";
import SelectEndTime from "@/components/molecules/SelectEndTime.vue";
import SelectJobCategory from "@/components/molecules/SelectJobCategory.vue";
import SelectFeature from "@/components/molecules/SelectFeature.vue";

export default createComponent({
  components: {
    SearchInputBoxMain: SearchInputBoxMain,
    SelectDailySalary: SelectDailySalary,
    SelectLocationPrefecture: SelectLocationPrefecture,
    SelectStartTime: SelectStartTime,
    SelectEndTime: SelectEndTime,
    SelectJobCategory: SelectJobCategory,
    SelectFeature: SelectFeature,
  },
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const searching = computed(() => displayui.searching);
    const toggleSearching = displayui.toggleSearching;

    const currentSearchComponent = computed(
      () => displayui.currentSearchComponent
    );

    return {
      searching,
      toggleSearching,
      currentSearchComponent,
    };
  },
});
