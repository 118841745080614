






import { createComponent } from "@vue/composition-api";
import NoticeItemView from "@/components/templates/NoticeItemView.vue";

export default createComponent({
  components: {
    NoticeItemView,
  },
});
