import { reactive } from "@vue/composition-api";
import router from "@/router";
// import errorHandle from "@/lib/errorHandle.ts";

export default function displayUIStore() {
  // errorHandle();
  const state = reactive({
    loading: false,
    searching: false,
    drawer: false,
    headerSub: true,
    headerMyPage: false,
    isDetail: false,
    currentSearchComponent: "SearchInputBoxMain",
    currentBankComponent: "Bank",
    sortModal: false,
    sortType: "work_start_date",
    snackbar: false,
    snackMessage: "",
    snackbarType: "",
    dialogEncourageRegistration: false,
    dialogCommon: false,
    dialogCommonMessage: "",
    mainAlert: {
      status: 0,
      message: "",
      detail: "",
    },
    imageToggle: true,
    isEditing: false,
  });

  // 画面リフレッシュ時に確認する
  const refreshConfirm = function(e:any){
    if(state.isEditing){
      // 一部のブラウザのみここのテキストが反映される。(Chromeなどでは変更不可)
      const confirmMessage = "このサイトを再読み込みしますか？行った変更が保存されない可能性があります。";
      e.returnValue = confirmMessage;
      return confirmMessage;
    }
    e.returnValue = "";
    return "";
  };
  window.addEventListener('beforeunload', refreshConfirm, false);

  return {

    // 画面編集中かどうかを設定
    setEditing(value:boolean) {
      state.isEditing = value;

      // 編集中のみ、バウンススクロール不可にする
      if(state.isEditing){
        document.getElementsByTagName("html")![0].style.setProperty("overscroll-behavior-y", "none", "important");
      }
      else {
        document.getElementsByTagName("html")![0].style.removeProperty("overscroll-behavior-y");
      }
    },
    get isEditing():boolean {
      return state.isEditing;
    },

    get mainAlert() {
      return state.mainAlert;
    },
    resetMainAlert() {
      return (
        (state.mainAlert.status = 0),
        (state.mainAlert.message = ""),
        (state.mainAlert.detail = "")
      );
    },
    setMainAlert(s: number, m: string, d: string) {
      state.mainAlert.status = s;
      state.mainAlert.message = m;
      state.mainAlert.detail = d;
    },
    formatISO8601DateTime(date_string: string) {
      const dt = new Date(date_string);
      const pad = (val:any, len:any) => {
          let p = '';
          for (let i = 0; i < len; ++i) {
              p += '0';
          }
          return (p + String(val)).slice(-1 * len);
      };
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const days = dt.getDate();
      const hours = dt.getHours();
      const minutes = dt.getMinutes();
      const seconds = dt.getSeconds();
      return `${year}/${pad(month, 2)}/${pad(days, 2)} ${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
    },

    get loading() {
      return state.loading;
    },

    get searching() {
      return state.searching;
    },

    get imageToggle() {
      return state.imageToggle;
    },

    //snackbar
    get snackbar() {
      return state.snackbar;
    },
    get snackMessage() {
      return state.snackMessage;
    },
    get snackbarType() {
      return state.snackbarType;
    },
    showSnackBar() {
      state.snackbar = !state.snackbar;
    },
    closeSnackBar() {
      state.snackbar = false;
    },
    changeSnackMessage(message: string) {
      state.snackMessage = message;
    },
    changeSnackbarType(type: string) {
      state.snackbarType = type;
    },
    changeImageToggle() {
      return (state.imageToggle = !state.imageToggle);
    },

    toggleSearching() {
      return (state.searching = !state.searching);
    },

    toTopPage(forceFlg:boolean = false) {
      this.leaveSearching();
      this.leaveDrawer();
      if(forceFlg){
        // 通常の画面遷移(検索条件等の画面状態クリア)
        location.href = "/";
      }
      else{
        // 疑似的な画面遷移(画面状態保持)
        router.push("/");
      }
    },

    leaveSearching() {
      return (state.searching = false);
    },
    leaveSearchingClose() {
      document.getElementById("body")!.style.display = "block";
      document.getElementById("page-footer")!.style.display = "block";
      document.getElementById("inputinner")!.style.height = "auto";
      return (state.searching = false);
    },
    onSearching() {
      return (state.searching = true);
    },
    get drawer() {
      return state.drawer;
    },
    openDrawer() {
      return (state.drawer = true);
    },
    leaveDrawer() {
      return (state.drawer = false);
    },

    get headerSub() {
      return state.headerSub;
    },
    toggleHeaderSub() {
      return (state.headerSub = !state.headerSub);
    },
    leaveHeaderSub() {
      return (state.headerSub = false);
    },
    onHeaderSub() {
      return (state.headerSub = true);
    },
    get headerMyPage() {
      return state.headerMyPage;
    },
    leaveHeaderMyPage() {
      return (state.headerMyPage = false);
    },
    onHeaderMyPage() {
      return (state.headerMyPage = true);
    },
    get isDetail() {
      return state.isDetail;
    },
    leaveIsDetail() {
      return (state.isDetail = false);
    },
    onIsDetail() {
      return (state.isDetail = true);
    },
    get currentSearchComponent() {
      return state.currentSearchComponent;
    },

    changeSearchComponent(componentName: string) {
      return (state.currentSearchComponent = componentName);
    },

    get dialogEncourageRegistration() {
      return state.dialogEncourageRegistration;
    },
    openDialogEncourageRegistration() {
      state.dialogEncourageRegistration = true;
    },
    closeDialogEncourageRegistration() {
      state.dialogEncourageRegistration = false;
    },

    get dialogCommon() {
      return state.dialogCommon;
    },
    get dialogCommonMessage() {
      return state.dialogCommonMessage;
    },
    openDialogCommon(msg: string) {
      state.dialogCommonMessage = msg;
      state.dialogCommon = true;
    },
    closeDialogCommon() {
      state.dialogCommon = false;
      state.dialogCommonMessage = "";
    },

    get currentBankComponent() {
      return state.currentBankComponent;
    },

    changeBankComponent(componentName: string) {
      return (state.currentBankComponent = componentName);
    },

    toggleSortModal() {
      state.sortModal = !state.sortModal;
    },

    get sortModal() {
      return state.sortModal;
    },

    get sortType() {
      return state.sortType;
    },

    changeSortType(sort_type_name: string) {
      state.sortType = sort_type_name;
    },
  };
}

export type DisplayUIStore = ReturnType<typeof displayUIStore>;
