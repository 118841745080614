import { reactive } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";
import Filters from "@/lib/filters";
import _ from "lodash";

export default function registrationStore() {
  const state = reactive({
    loading: false,
    loadingCount: 0,
    response: <any>{
      status: 0,
    },
    headers: <any>{
      "content-type": "application/json",
    },
    formHeaders: <any>{
      "content-type": "multipart/form-data",
    },
    mail: "",
    rootList: [],
    operationList: [],
    sexList: [
      { code: "1", name: "男性" },
      { code: "2", name: "女性" },
    ],
    // 国籍一覧 code:55元から抜けている
    nationalityList: [
      { code: "00", name: "日本" },
      { code: "01", name: "中国" },
      { code: "02", name: "韓国" },
      { code: "03", name: "ブラジル" },
      { code: "04", name: "フィリピン" },
      { code: "05", name: "台湾" },
      { code: "06", name: "朝鮮" },
      { code: "07", name: "モンゴル" },
      { code: "08", name: "マレーシア" },
      { code: "09", name: "ベトナム" },
      { code: "10", name: "アイスランド" },
      { code: "100", name: "セントビンセント・グレナディーン" },
      { code: "101", name: "セントルシア" },
      { code: "102", name: "ソマリア" },
      { code: "103", name: "ソロモン諸島" },
      { code: "104", name: "タイ" },
      { code: "105", name: "タジキスタン" },
      { code: "106", name: "タヒチ" },
      { code: "107", name: "タンザニア" },
      { code: "108", name: "チェコ" },
      { code: "109", name: "チベット" },
      { code: "11", name: "アイルランド" },
      { code: "110", name: "チャド" },
      { code: "111", name: "中央アフリカ" },
      { code: "112", name: "チュニジア" },
      { code: "113", name: "チリ" },
      { code: "114", name: "ツバル" },
      { code: "115", name: "デンマーク" },
      { code: "116", name: "ドイツ" },
      { code: "117", name: "トーゴ" },
      { code: "118", name: "ドミニカ国" },
      { code: "119", name: "ドミニカ共和国" },
      { code: "12", name: "アゼルバイジャン" },
      { code: "120", name: "トリニダード・トバゴ" },
      { code: "121", name: "トルクメニスタン" },
      { code: "122", name: "トルコ" },
      { code: "123", name: "トンガ" },
      { code: "124", name: "ナイジェリア" },
      { code: "125", name: "ナウル" },
      { code: "126", name: "ナミビア" },
      { code: "127", name: "ニカラグア" },
      { code: "128", name: "西サハラ" },
      { code: "129", name: "ニジェール" },
      { code: "13", name: "アフガニスタン" },
      { code: "130", name: "ニュージーランド" },
      { code: "131", name: "ネパール" },
      { code: "132", name: "ノルウェー" },
      { code: "133", name: "バーレーン" },
      { code: "134", name: "ハイチ" },
      { code: "135", name: "パキスタン" },
      { code: "136", name: "バチカン" },
      { code: "137", name: "パナマ" },
      { code: "138", name: "バヌアツ" },
      { code: "139", name: "バハマ" },
      { code: "14", name: "アメリカ合衆国" },
      { code: "140", name: "パプアニューギニア" },
      { code: "141", name: "パラオ" },
      { code: "142", name: "パラグアイ" },
      { code: "143", name: "バルバドス" },
      { code: "144", name: "パレスチナ" },
      { code: "145", name: "ハンガリー" },
      { code: "146", name: "バングラデシュ" },
      { code: "147", name: "東ティモール" },
      { code: "148", name: "フィジー" },
      { code: "149", name: "フィンランド" },
      { code: "15", name: "アラブ首長国連邦" },
      { code: "150", name: "ブータン" },
      { code: "151", name: "フランス" },
      { code: "152", name: "ブルガリア" },
      { code: "153", name: "ブルキナファソ" },
      { code: "154", name: "ブルネイ" },
      { code: "155", name: "ブルンジ" },
      { code: "156", name: "ベナン" },
      { code: "157", name: "ベネズエラ" },
      { code: "158", name: "ベラルーシ" },
      { code: "159", name: "ベリーズ" },
      { code: "16", name: "アルジェリア" },
      { code: "160", name: "ペルー" },
      { code: "161", name: "ベルギー" },
      { code: "162", name: "ポーランド" },
      { code: "163", name: "ボスニア・ヘルツェゴビナ" },
      { code: "164", name: "ボツワナ" },
      { code: "165", name: "ボリビア" },
      { code: "166", name: "ポルトガル" },
      { code: "167", name: "香港" },
      { code: "168", name: "ホンジュラス" },
      { code: "169", name: "マーシャル諸島" },
      { code: "17", name: "アルゼンチン" },
      { code: "170", name: "マカオ" },
      { code: "171", name: "マケドニア" },
      { code: "172", name: "マダガスカル" },
      { code: "173", name: "マラウイ" },
      { code: "174", name: "マリ" },
      { code: "175", name: "マルタ" },
      { code: "176", name: "ミクロネシア" },
      { code: "177", name: "南アフリカ" },
      { code: "178", name: "ミャンマー" },
      { code: "179", name: "メキシコ" },
      { code: "18", name: "アルバニア" },
      { code: "180", name: "モーリシャス" },
      { code: "181", name: "モーリタニア" },
      { code: "182", name: "モザンビーク" },
      { code: "183", name: "モナコ" },
      { code: "184", name: "モルディブ" },
      { code: "185", name: "モルドバ" },
      { code: "186", name: "モロッコ" },
      { code: "187", name: "モンテネグロ" },
      { code: "188", name: "ヨルダン" },
      { code: "189", name: "ラオス" },
      { code: "19", name: "アルメニア" },
      { code: "190", name: "ラトビア" },
      { code: "191", name: "リトアニア" },
      { code: "192", name: "リビア" },
      { code: "193", name: "リヒテンシュタイン" },
      { code: "194", name: "リベリア" },
      { code: "195", name: "ルーマニア" },
      { code: "196", name: "ルクセンブルグ" },
      { code: "197", name: "ルワンダ" },
      { code: "198", name: "レソト" },
      { code: "199", name: "レバノン" },
      { code: "20", name: "アンゴラ" },
      { code: "200", name: "ロシア" },
      { code: "21", name: "アンティグアバーブーダ" },
      { code: "22", name: "アンドラ" },
      { code: "23", name: "イエメン" },
      { code: "24", name: "イギリス" },
      { code: "25", name: "イスラエル" },
      { code: "26", name: "イタリア" },
      { code: "27", name: "イラク" },
      { code: "28", name: "イラン" },
      { code: "29", name: "インド" },
      { code: "30", name: "インドネシア" },
      { code: "31", name: "ウガンダ" },
      { code: "32", name: "ウクライナ" },
      { code: "33", name: "ウズベキスタン" },
      { code: "34", name: "ウルグアイ" },
      { code: "35", name: "エクアドル" },
      { code: "36", name: "エジプト" },
      { code: "37", name: "エストニア" },
      { code: "38", name: "エチオピア" },
      { code: "39", name: "エリトリア" },
      { code: "40", name: "エルサルバドル" },
      { code: "41", name: "オーストラリア" },
      { code: "42", name: "オーストリア" },
      { code: "43", name: "オマーン" },
      { code: "44", name: "オランダ" },
      { code: "45", name: "ガーナ" },
      { code: "46", name: "カボベルデ" },
      { code: "47", name: "ガイアナ" },
      { code: "48", name: "カザフスタン" },
      { code: "49", name: "カタール" },
      { code: "50", name: "カナダ" },
      { code: "51", name: "ガボン" },
      { code: "52", name: "カメルーン" },
      { code: "53", name: "ガンビア" },
      { code: "54", name: "カンボジア" },
      { code: "56", name: "ギニア" },
      { code: "57", name: "ギニアビサウ" },
      { code: "58", name: "キプロス" },
      { code: "59", name: "キューバ" },
      { code: "60", name: "ギリシャ" },
      { code: "61", name: "キリバス" },
      { code: "62", name: "キルギス" },
      { code: "63", name: "グアテマラ" },
      { code: "64", name: "クウェート" },
      { code: "65", name: "ジョージア" },
      { code: "66", name: "グレナダ" },
      { code: "67", name: "クロアチア" },
      { code: "68", name: "ケニア" },
      { code: "69", name: "コートジボワール" },
      { code: "70", name: "コスタリカ" },
      { code: "71", name: "コモロ" },
      { code: "72", name: "コロンビア" },
      { code: "73", name: "コンゴ（旧ザイール）" },
      { code: "74", name: "コンゴ共和国" },
      { code: "75", name: "サウジアラビア" },
      { code: "76", name: "サモア" },
      { code: "77", name: "サントメプリンシペ" },
      { code: "78", name: "ザンビア" },
      { code: "79", name: "サンマリノ" },
      { code: "80", name: "シエラレオネ" },
      { code: "81", name: "ジブチ" },
      { code: "82", name: "ジャマイカ" },
      { code: "83", name: "シリア" },
      { code: "84", name: "シンガポール" },
      { code: "85", name: "ジンバブエ" },
      { code: "86", name: "スイス" },
      { code: "87", name: "スウェーデン" },
      { code: "88", name: "スーダン" },
      { code: "89", name: "スペイン" },
      { code: "90", name: "スリナム" },
      { code: "91", name: "スリランカ" },
      { code: "92", name: "スロバキア" },
      { code: "93", name: "スロベニア" },
      { code: "94", name: "スワジランド" },
      { code: "95", name: "セイシェル" },
      { code: "96", name: "セネガル" },
      { code: "97", name: "セルビア" },
      { code: "98", name: "赤道ギニア" },
      { code: "99", name: "セントクリストファーネビス" },
    ],
    // 本人確認書類一覧(code番号要確認)
    identifyList: [
      { code: "01", name: "運転免許証" },
      { code: "02", name: "健康保険証／国民健康保険証" },
      { code: "05", name: "住民票の写し" },
      { code: "15", name: "在留カード" },
      { code: "17", name: "マイナンバーカード" },
    ],
    // 確認書類アップロード格納
    imageSrcList: <any>[],
    imageOtherSrcList: <any>[],
    imageIndex: 0,
    // 自撮り書類撮影位置ランダム指定用
    instruction: [
      { to: "下側", param: "Under the face" },
      { to: "左側", param: "Left of face" },
      { to: "右側", param: "Right of face" },
    ],
    // 表示されたinstructionのparamが入る
    instructionParam: "",
    pepList: [],
    classList: [],
    statusList: [],
    displayParams: {
      name: "",
      name1: "",
      name2: "",
      nameKana: "",
      nameKana1: "",
      nameKana2: "",
      sex: "",
      birthday: "20000101",
      birthdayYear: "2000",
      birthdayMonth: "01",
      birthdayDate: "01",
      zip_address: "",
      address1: "",
      address2: "",
      phone_number: "",
      pep: true,
      identify: "",
      document: "",
      nationality: "",
      password_pin: "",
      mail_address: "",
    },
    displayError: {
      name: "",
      name1: "",
      name2: "",
      nameKana: "",
      nameKana1: "",
      nameKana2: "",
      sex: "",
      birthday: "",
      birthdayYear: "",
      birthdayMonth: "",
      birthdayDate: "",
      zip_address: "",
      address1: "",
      address2: "",
      phone_number: "",
      pep: "",
      nationality: "",
      password_pin: "",
      identify: "",
      document: "",
      mail_address: "",
    },
    displayErrorFlag: false,
    searchText: "",
    targetCompany: {
      id: "",
      name: "",
    },
    faceMatch: "",
    faceMatchScore: "",
    submitFlag: false,
    cacheEvasionString: "abcdefghijklmnopqrstuvwxyz",
    judgementMember: <any>{},
    identifyFileObjectList: [],
    authCode: "",
    entryKey: "",
    message: "",
    redirectTo: "",
  });
  const startLoading = () => {
    state.loadingCount++;
    state.loading = state.loadingCount > 0;
  };
  const stopLoading = () => {
    state.loadingCount--;
    state.loading = state.loadingCount > 0;
  };

  const axiosInstanceJobpay = axios.create({
    baseURL: `${process.env.VUE_APP_MEMBER_API_SERVER}`,
  });
  axiosInstanceJobpay.defaults.timeout = 30000;
  axiosInstanceJobpay.interceptors.response.use(
    function (response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function (error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  const isZenkaku = function(str:string) {
    return (String(str).match(/[\x01-\x7E\uFF65-\uFF9F]/)) ? false : true;
  };
  const isZenkakuKataKana = function(str:string) {
    return !!str.match(/^[ァ-ヶー　]*$/);
  };

  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosInstance.defaults.timeout = 30000;
  axiosInstance.interceptors.response.use(
    function(response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function(error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  //  既存input~を1つに、引数によってどの入力欄か判別、stateに値を渡す
  const inputValue = function(value: any, category: string) {
    if(typeof value !== "boolean"){
      if(value !== null && value !== undefined){
        value = String(value)
      }
      else{
        value = ""
      }
    }

    if (category === "name1") {
      state.displayParams.name1 = value;
    }
    if (category === "name2") {
      state.displayParams.name2 = value;
    }
    if (category === "nameKana1") {
      state.displayParams.nameKana1 = value;
    }
    if (category === "nameKana2") {
      state.displayParams.nameKana2 = value;
    }
    if (category === "sex") {
      state.displayParams.sex = value;
    }
    if (category === "zip_address") {
      value = value.replace(/[^0-9]+/i, "");
      if (value.length > 7) {
        value = value.slice(0, 7);
      }
      state.displayParams.zip_address = value;
    }
    if (category === "address1") {
      state.displayParams.address1 = value;
    }
    if (category === "address2") {
      state.displayParams.address2 = value;
    }
    if (category === "phone_number") {
      value = value.replace(/[^0-9]+/i, "");
      if (value.length > 11) {
        value = value.slice(0, 11);
      }
      state.displayParams.phone_number = value;
    }
    if (category === "nationality") {
      state.displayParams.nationality = value;
    }
    if (category === "pep") {
      if(value){
        state.displayParams.pep = true;
      }
      else{
        state.displayParams.pep = false;
      }
    }
    if (category === "password_pin") {
      value = value.replace(/[^0-9]+/i, "");
      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      state.displayParams.password_pin = value;
    }
    if (category === "identify") {
      state.displayParams.identify = value;
    }
    if (category === "document") {
      state.displayParams.document = value;
    }
    if (category === "mail_address") {
      state.displayParams.mail_address = value;
    }
    if (category === "birthday") {
      const birthYmd = value;
      if(birthYmd.length == 8){
        state.displayParams.birthday = birthYmd;
        state.displayParams.birthdayYear = birthYmd.substr(0, 4);
        state.displayParams.birthdayMonth = birthYmd.substr(4, 2);
        state.displayParams.birthdayDate = birthYmd.substr(6, 2);
      }
    }
  };
  const inputMail = function(e: string) {
    state.mail = e;
  };

  // VP登録の入力チェック
  const validateVpEntryDisplayParams = function(category: string) {
    if (category === "name1") {
      if(state.displayParams.name1 === ""){
        state.displayError.name1 = "お名前（姓）を入力してください";
        state.displayErrorFlag = true;
      }
      else if(!isZenkaku(state.displayParams.name1)){
        state.displayError.name1 = "お名前（姓）は全角文字で入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "name2") {
      if (state.displayParams.name2 === "") {
        state.displayError.name2 = "お名前（名）を入力してください";
        state.displayErrorFlag = true;
      }
      else if(!isZenkaku(state.displayParams.name2)){
        state.displayError.name2 = "お名前（名）は全角文字で入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "nameKana1") {
      if (state.displayParams.nameKana1 === "") {
        state.displayError.nameKana1 = "お名前（セイ）を入力してください";
        state.displayErrorFlag = true;
      }
      else if (!isZenkakuKataKana(state.displayParams.nameKana1)) {
        state.displayError.nameKana1 = "お名前（セイ）は全角カタカナで入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "nameKana2") {
      if (state.displayParams.nameKana2 === "") {
        state.displayError.nameKana2 = "お名前（メイ）を入力してください";
        state.displayErrorFlag = true;
      }
      else if (!isZenkakuKataKana(state.displayParams.nameKana2)) {
        state.displayError.nameKana2 = "お名前（メイ）は全角カタカナで入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "sex" && state.displayParams.sex === "") {
      state.displayError.sex = "性別を選択してください";
      state.displayErrorFlag = true;
    }
    if (category === "birthday" && state.displayParams.birthday.length != 8) {
      state.displayError.birthday = "生年月日を入力してください";
      state.displayErrorFlag = true;
    }
    if (
      category === "zip_address" &&
      Filters.onlyHalfNumber(state.displayParams.zip_address).length < 7
    ) {
      state.displayError.zip_address = "郵便番号を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "address1" && state.displayParams.address1 === "") {
      state.displayError.address1 = "住所１を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "address2" && state.displayParams.address2 === "") {
      state.displayError.address2 = "住所２を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "phone_number") {
      if (
        state.displayParams.phone_number === "" ||
        Filters.onlyHalfNumber(state.displayParams.phone_number).length < 10 ||
        Filters.onlyHalfNumber(state.displayParams.phone_number).length > 11
      ) {
        state.displayError.phone_number = "電話番号を10〜11桁で入力してください";
        state.displayErrorFlag = true;
      }
    }
  };

  // 入力チェック
  const validateDisplayParams = function(category: string) {
    if (category === "name1") {
      if(state.displayParams.name1 === ""){
        state.displayError.name1 = "お名前（姓）を入力してください";
        state.displayErrorFlag = true;
      }
      else if(!isZenkaku(state.displayParams.name1)){
        state.displayError.name1 = "お名前（姓）は全角文字で入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "name2") {
      if (state.displayParams.name2 === "") {
        state.displayError.name2 = "お名前（名）を入力してください";
        state.displayErrorFlag = true;
      }
      else if(!isZenkaku(state.displayParams.name2)){
        state.displayError.name2 = "お名前（名）は全角文字で入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "nameKana1") {
      if (state.displayParams.nameKana1 === "") {
        state.displayError.nameKana1 = "お名前（セイ）を入力してください";
        state.displayErrorFlag = true;
      }
      else if (!isZenkakuKataKana(state.displayParams.nameKana1)) {
        state.displayError.nameKana1 = "お名前（セイ）は全角カタカナで入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "nameKana2") {
      if (state.displayParams.nameKana2 === "") {
        state.displayError.nameKana2 = "お名前（メイ）を入力してください";
        state.displayErrorFlag = true;
      }
      else if (!isZenkakuKataKana(state.displayParams.nameKana2)) {
        state.displayError.nameKana2 = "お名前（メイ）は全角カタカナで入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (category === "sex" && state.displayParams.sex === "") {
      state.displayError.sex = "性別を選択してください";
      state.displayErrorFlag = true;
    }
    if (category === "birthday" && state.displayParams.birthday.length != 8) {
      state.displayError.birthday = "生年月日を入力してください";
      state.displayErrorFlag = true;
    }
    if (
      category === "zip_address" &&
      Filters.onlyHalfNumber(state.displayParams.zip_address).length < 7
    ) {
      state.displayError.zip_address = "郵便番号を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "address1" && state.displayParams.address1 === "") {
      state.displayError.address1 = "住所１を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "address2" && state.displayParams.address2 === "") {
      state.displayError.address2 = "住所２を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "phone_number") {
      if (
        state.displayParams.phone_number === "" ||
        Filters.onlyHalfNumber(state.displayParams.phone_number).length < 10 ||
        Filters.onlyHalfNumber(state.displayParams.phone_number).length > 11
      ) {
        state.displayError.phone_number = "電話番号を10〜11桁で入力してください";
        state.displayErrorFlag = true;
      }
    }
    if (
      category === "nationality" &&
      state.displayParams.nationality === ""
    ) {
      state.displayError.nationality = "国籍を選択してください";
      state.displayErrorFlag = true;
    }
    if (category === "pep" && state.displayParams.pep === false) {
      state.displayError.pep = "外国人PEPs情報を選択してください";
      state.displayErrorFlag = true;
    }
    if (
      category === "password_pin" &&
      Filters.onlyHalfNumber(state.displayParams.password_pin).length < 4 &&
      state.displayParams.password_pin === ""
    ) {
      state.displayError.password_pin = "暗証番号を入力してください";
      state.displayErrorFlag = true;
    }
    if (category === "document" && state.displayParams.document === "") {
      state.displayError.document = "本人確認書類を選択してください";
      state.displayErrorFlag = true;
    }
    if (category === "identify" && state.displayParams.identify === "") {
      state.displayError.identify = "本人確認書類提出方法を選択してください";
      state.displayErrorFlag = true;
    }
    if (category === "mail_address") {
      if (!Filters.checkMailAddressFormat(state.displayParams.mail_address)) {
        state.displayError.mail_address = "メールアドレスを入力してください";
        state.displayErrorFlag = true;
      }
    }
  };

  // 入力チェック
  const clearDisplayError = function(category: string) {
    state.displayErrorFlag = false;
    if (category === "name1" && state.displayParams.name1 !== "") {
      state.displayError.name1 = "";
    }
    if (category === "name2" && state.displayParams.name2 !== "") {
      state.displayError.name2 = "";
    }
    if (category === "nameKana1" && state.displayParams.nameKana1 !== "") {
      state.displayError.nameKana1 = "";
    }
    if (category === "nameKana2" && state.displayParams.nameKana2 !== "") {
      state.displayError.nameKana2 = "";
    }
    if (category === "sex" && state.displayParams.sex !== "") {
      state.displayError.sex = "";
    }
    if (category === "birthday" && state.displayParams.birthday !== "") {
      state.displayError.birthday = "";
    }
    if (category === "zip_address" ) {
      if (
        Filters.onlyHalfNumber(state.displayParams.zip_address).length <= 7
      ) {
        state.displayError.zip_address = "";
      }
    }
    if (category === "address1" && state.displayParams.address1 !== "") {
      state.displayError.address1 = "";
    }
    if (category === "address2" && state.displayParams.address2 !== "") {
      state.displayError.address2 = "";
    }
    if (category === "phone_number") {
      if (Filters.onlyHalfNumber(state.displayParams.phone_number).length >= 10 &&
        Filters.onlyHalfNumber(state.displayParams.phone_number).length <= 11
      ) {
        state.displayError.phone_number = "";
      }
    }
    if (
      category === "nationality" &&
      state.displayParams.nationality !== ""
    ) {
      state.displayError.nationality = "";
    }
    if (category === "pep") {
      state.displayError.pep = "";
    }
    if (
      category === "password_pin" &&
      state.displayParams.password_pin !== ""
    ) {
      state.displayError.password_pin = "";
    }
    if (category === "document" && state.displayParams.document !== "") {
      state.displayError.document = "";
    }
    if (category === "identify" && state.displayParams.identify !== "") {
      state.displayError.identify = "";
    }
    if (category === "mail_address") {
      state.displayError.mail_address = "";
    }
  };

  return {
    get loading(): boolean {
      return state.loading;
    },
    get response() {
      return state.response;
    },
    get mail() {
      return state.mail;
    },
    get displayParams() {
      return state.displayParams;
    },
    get displayError() {
      return state.displayError;
    },
    get displayErrorFlag() {
      return state.displayErrorFlag;
    },
    get instruction() {
      return state.instruction;
    },
    get instructionParam() {
      return state.instructionParam;
    },
    get sexList() {
      return state.sexList;
    },
    get nationalityList() {
      return state.nationalityList;
    },
    get identifyList() {
      return state.identifyList;
    },
    get imageSrcList() {
      return state.imageSrcList;
    },
    get imageOtherSrcList() {
      return state.imageOtherSrcList;
    },
    get imageIndex() {
      return state.imageIndex;
    },
    countImageIndex() {
      state.imageIndex++;
    },
    resetImageIndex() {
      state.imageIndex = 0;
    },

    get faceMatch() {
      return state.faceMatch;
    },
    get faceMatchScore() {
      return state.faceMatchScore;
    },
    get message(): string {
      return state.message;
    },
    get redirectTo(): string {
      return state.redirectTo;
    },
    clearRedirectTo() {
      state.redirectTo = "";
    },
    get entryKey() {
      return state.entryKey;
    },
    setAuthorizationHeader(token: string) {
      state.headers.Authorization = `Bearer ${token}`;
      state.formHeaders.Authorization = `Bearer ${token}`;
    },

    // 変更後メールアドレスチェック
    async requestPersonalEditCheckMailaddress() {
      startLoading();

      const params = {
        mail_address: state.displayParams.mail_address,
      };

      return await axiosInstance
        .get(
          "/member/personal/edit/check/mailaddress",
          {
            params: params,
            headers: {
              "content-type": "application/json",
              "Authorization": localStorage.getItem("token")
            }
          }
        )
        .then((response) => {
          state.message = "";
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    // エントリーデータ更新API(会員登録のエントリーAPI)
    async postPersonalEditSendCodeMail() {
      startLoading();

      const params = {
        mail_address: state.displayParams.mail_address,
      };

      return await axiosInstance
        .post(
          "/member/personal/edit/sendcode",
          params,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          state.message = "";
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    // 会員情報更新API
    async postPersonalEditConfirm() {
      startLoading();

      const params = {
        last_name: state.displayParams.name1,
        first_name: state.displayParams.name2,
        last_name_kana: state.displayParams.nameKana1,
        first_name_kana: state.displayParams.nameKana2,
        gender_code: state.displayParams.sex,
        birthday: state.displayParams.birthday,
        zip_code: state.displayParams.zip_address,
        address_1: state.displayParams.address1,
        address_2: state.displayParams.address2,
        phone_number: state.displayParams.phone_number,
        mail_address: state.displayParams.mail_address,
        pin: state.authCode,
      };

      return await axiosInstance
        .post(
          "/member/personal/edit/confirm",
          params,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          state.message = "";
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    // 住所検索
    getZipAddress() {
      startLoading();

      return axiosInstance
        .get("info/address", {
          params: {
            zipcode: state.displayParams.zip_address
          },
        })
        .then((response) => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "郵便番号に該当する住所がありません。";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          const resultAddress = apiResponseData.address;
          state.displayParams.address1 =
            resultAddress.state + resultAddress.city + resultAddress.town;
          return response.status;
        })
        .catch(error => {})
        .finally(() => stopLoading());
    },
    postVerify() {
      // 会員登録新API用--メールアドレス・認証コード検証API
      startLoading();
      
      return axiosInstance
        .post(
          "entry/registration/pin/verify",
          {
            mail_address: state.mail,
            pin: state.authCode,
          },
          {
            headers: state.headers,
          }
        )
        .then((response) => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "認証コードとメールアドレスの検証が正しく行えませんでした。";
            if(apiResponse){
              if(apiResponse.message){
                state.message = apiResponse.message;
              }
              // ログイン画面へのリダイレクト指定
              if(apiResponse.response_code == '31'){
                state.redirectTo = '/login';
              }
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;

          if (apiResponseData.entry_key !== "") {
            state.entryKey = apiResponseData.entry_key;
          }

          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    // エントリーデータ更新API(会員登録のエントリーAPI)
    async postEntryUpdate() {
      startLoading();

      let pep_answer = "";
      if (state.displayParams.pep === true) {
        pep_answer = "該当しない";
      } else {
        pep_answer = "該当する";
      }
      const params = {
        mail_address: state.mail,
        employee_number: "",
        cyurica_card_no: "",
        last_name: state.displayParams.name1,
        first_name: state.displayParams.name2,
        last_name_kana: state.displayParams.nameKana1,
        first_name_kana: state.displayParams.nameKana2,
        gender_code: state.displayParams.sex,
        birthday: state.displayParams.birthday,
        zip_code: state.displayParams.zip_address,
        address_1: state.displayParams.address1,
        address_2: state.displayParams.address2,
        phone_number: state.displayParams.phone_number,
        pin_code: state.displayParams.password_pin,
        identification_code: state.displayParams.document,
        department_code: "",
        employee_type: "",
        peps: pep_answer,
        nationality: state.displayParams.nationality,
        company_name: "",
        instruction: state.instructionParam,
        score: state.faceMatchScore
      };

      return await axiosInstance
        .post("entry/registration/" + state.entryKey + "/update", params, {
          headers: state.headers,
        })
        
        .then((response) => {
          state.message = "";
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },
    // 会員登録確定API
    async postEntryConfirm(isVpTermsAgree:boolean) {
      startLoading();

      const params:any = {
        entry_type: 1, // 新規登録
      }
      // 規約同意あり
      if(isVpTermsAgree){
        params['vp_terms_agree'] = '1';
      }

      return await axiosInstance
        .post("entry/registration/" + state.entryKey + "/confirm", params, {
          headers: state.headers
        })
        .then(response => {
          state.message = "";
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => (state.message = "サーバーでエラーが発生しました"))
        .finally(() => stopLoading());
    },

    // eKYCの本人確認書類と顔写真比較API反映
    compareFaceJobpay() {
      startLoading();

      // eKYC skip
      let flgEkycSkip = false;
      const eKycSkipEnabled = process.env.VUE_APP_EKYC_SKIP;
      if(eKycSkipEnabled && state.mail.split('@')[0].endsWith('skip')){
        flgEkycSkip = true;
      }

      const params = {
        front_image_data: state.imageSrcList[0],
        portrait_image_data: state.imageOtherSrcList[0]
      };

      return axiosInstanceJobpay
        .post("entry/" + state.entryKey + "/ekyc/judge/", params, {
          headers: state.headers,
        })
        .then((response) => {
          // レスポンス200
          if (response.status === 200) {
            state.faceMatch = response.data.result;
            state.faceMatchScore = response.data.score;
            if (response.data.result === "fail") {
              if(flgEkycSkip){
                state.message = "";
                state.faceMatch = "success";
              }
              else {
                state.message =
                  "正面から撮影した写真と個人確認用の証明書の顔写真が一致しませんでした";
              }
            }
            if (response.data.result === "retry") {
              state.message =
                "証明書の顔写真を認識できませんでした。再度撮影をお願い致します";
            }
          } else {
            // レスポンス500
            state.faceMatch = "";
            state.message = "サーバーでエラーが発生しました";
          }
        })
        .catch(error => {
          state.faceMatch = "error";
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    postVerifyForJobpay() {
      // 会員登録新API用--メールアドレス・認証コード検証API
      startLoading();
      
      return axiosInstance
        .post(
          "entry/jobpay/pin/verify",
          {
            mail_address: state.mail,
            pin: state.authCode,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;

          if (apiResponseData.entry_key !== "") {
            state.entryKey = apiResponseData.entry_key;
          }

          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },

    postEntryIdentifyForJobpay(src: string) {
      // 「エントリーキーに合致するデータの画像を更新API」を叩く
      // このAPIのみ、ファイルアップロードのためJOBPAYのAPIを直接叩く
      startLoading();

      const params = {
        image_index: 0,
        image_data: "",
      };
      params.image_index = state.imageIndex;
      params.image_data = src;

      return axiosInstanceJobpay
        .post("entry/" + state.entryKey + "/identify/", params, {
          headers: state.headers,
        })
        .then((response) => {
          if (response.status >= 400 || !response.data || response.data.file_path === "") {
            state.imageIndex = 0;
            state.message =
              "画像のアップロードに失敗しました。もう一度次へボタンを押してください";
          }
        })
        .catch((error) => {
          state.imageIndex = 0;
          // state.message =
          //   "画像のアップロードに失敗しました。もう一度次へボタンを押してください";
        })
        .finally(() => stopLoading());
    },
    // エントリーデータ更新API(会員登録のエントリーAPI)
    async postEntryUpdateForJobpay() {
      startLoading();

      let pep_answer = "";
      if (state.displayParams.pep === true) {
        pep_answer = "該当しない";
      } else {
        pep_answer = "該当する";
      }
      const params = {
        mail_address: state.mail,
        employee_number: "",
        cyurica_card_no: "",
        last_name: state.displayParams.name1,
        first_name: state.displayParams.name2,
        last_name_kana: state.displayParams.nameKana1,
        first_name_kana: state.displayParams.nameKana2,
        gender_code: state.displayParams.sex,
        birthday: state.displayParams.birthday,
        zip_code: state.displayParams.zip_address,
        address_1: state.displayParams.address1,
        address_2: state.displayParams.address2,
        phone_number: state.displayParams.phone_number,
        pin_code: state.displayParams.password_pin,
        identification_code: state.displayParams.document,
        department_code: "",
        employee_type: "",
        peps: pep_answer,
        nationality: state.displayParams.nationality,
        company_name: "",
        instruction: state.instructionParam,
        score: state.faceMatchScore
      };

      return await axiosInstance
        .post("entry/jobpay/" + state.entryKey + "/update", params, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        
        .then((response) => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },
    // 会員登録確定API
    async postEntryConfirmForJobpay() {
      startLoading();
      const params = {}
      return await axiosInstance
        .post("entry/jobpay/" + state.entryKey + "/confirm", params, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(response => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => (state.message = "サーバーでエラーが発生しました"))
        .finally(() => stopLoading());
    },

    submit() {
      state.submitFlag = true;
    },
    // 入力チェック
    validateDisplayParams(category: string) {
      validateDisplayParams(category);
    },
    validateVpEntryDisplayParams(category: string) {
      validateVpEntryDisplayParams(category);
    },

    isPersonalEditing() {
      if(state.displayParams.mail_address != ""){
        return true;
      }
      return false;
    },

    setCustomerInfo(customerInfo:any) {
      inputValue(customerInfo.name_first, "name1");
      inputValue(customerInfo.name_last, "name2");
      inputValue(customerInfo.name_kana_first, "nameKana1");
      inputValue(customerInfo.name_kana_last, "nameKana2");
      inputValue(customerInfo.sex_code, "sex");
      inputValue(customerInfo.zipcode, "zip_address");
      inputValue(customerInfo.address1, "address1");
      inputValue(customerInfo.address2, "address2");
      inputValue(customerInfo.phone, "phone_number");
      inputValue(customerInfo.birth_ymd, "birthday");

      // 「pepでない」チェックボックスなので反転
      if(String(customerInfo.peps) == '1'){
        inputValue(false, "pep");
      }
      else{
        inputValue(true, "pep");
      }

      inputValue(customerInfo.mail_address, "mail_address");
    },

    //  既存input~を1つに、引数によってどの入力欄か判別、stateに値を渡す
    inputValue(value: any, category: string) {
      return inputValue(value, category);
    },
    // state.instructionParamに撮影位置ランダム指定のparamを保存
    inputInstructionParam(e: string) {
      state.instructionParam = e;
    },

    inputBirthday() {
      state.displayParams.birthday = ""
      if(state.displayParams.birthdayYear.length == 4
        && state.displayParams.birthdayMonth.length == 2
        && state.displayParams.birthdayDate.length == 2){
        state.displayParams.birthday =
        state.displayParams.birthdayYear +
        state.displayParams.birthdayMonth +
        state.displayParams.birthdayDate;
      }
    },
    checkPep() {
      state.displayParams.pep = true;
    },
    inputIdentify(e: string) {
      state.displayParams.identify = e;
    },
    inputIdentifyList(e: string) {
      state.displayParams.document = e;
    },
    inputImageSrcList(e: Array<string>) {
      state.imageSrcList = e;
    },
    inputMail(e: string) {
      inputMail(e);
    },
    inputAuthCode(e: string) {
      state.authCode = e;
    },
    clearResponse() {
      state.response = {
        status: 0,
      };
    },
    clearMail() {
      state.mail = "";
    },
    clearDisplayParams() {
      state.displayParams = {
        name: "",
        name1: "",
        name2: "",
        nameKana: "",
        nameKana1: "",
        nameKana2: "",
        sex: "",
        birthday: "",
        birthdayYear: "",
        birthdayMonth: "",
        birthdayDate: "",
        zip_address: "",
        address1: "",
        address2: "",
        phone_number: "",
        identify: "",
        document: "",
        pep: false,
        nationality: "",
        password_pin: "",
        mail_address: "",
      };
    },
    // stateの値を空に
    clearInstructionParam() {
      state.instructionParam = "";
    },
    // stateの値を空に
    clearImageSrcList() {
      state.imageSrcList = [];
    },
    // stateの値を空に
    clearImageOtherSrcList() {
      state.imageOtherSrcList = [];
    },
    //  入力エラークリア
    clearDisplayError(category: string) {
      clearDisplayError(category);
    },
    clearAuthCode() {
      state.authCode = "";
    },
    clearMessage() {
      state.message = "";
    },
  };
}

export type RegistrationStore = ReturnType<typeof registrationStore>;
