



import { createComponent } from "@vue/composition-api";

import FavoriteOutlineSvg from "@/assets/svg/favorite_outline.svg";

export default createComponent({
  components: {
    FavoriteOutlineSvg,
  },
});
