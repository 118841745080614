
































































































import {
  createComponent,
  computed,
  inject,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import router from "@/router";
import { LOGIN_LINK_SETTINGS } from "@/constants/pageview";

import Button from "@/components/atoms/button/Button.vue";
import PageTitle from "@/components/atoms/text/PageTitle.vue";
// import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";
import EncourageRegistrationDialog from "@/components/templates/EncourageRegistrationDialog.vue";

export default createComponent({
  setup(props, context) {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const isViewRegistration = ref(true); // 新規登録誘導表示の表示制御

    onMounted(() => {
      const query:any = context.root.$route.query;

      displayui.closeDialogEncourageRegistration();
      displayui.leaveSearching();
      displayui.leaveDrawer();
      displayui.leaveHeaderSub();
      displayui.leaveHeaderMyPage();
      state.login_id = userinfo.value.login_id;

      // ログイン時のリダイレクト先によって処理分岐
      let redirectPath = query["redirect"];
      // 応募履歴とマイページの場合
      if(redirectPath == '/job-calendar' || redirectPath == '/my-page'){
        // 新規登録非表示
        //isViewRegistration.value = false;
      }
    });
    
    //正規表現効かせるためにv-modelに変更したため追加
    const state = reactive({
      login_id: "",
      password: ""
    })
    const userinfo = computed(() => user.userinfo);
    const login_id = computed(() => userinfo.value.login_id);
    const password = userinfo.value.password;

    const inputLoginId = () => {
      // 正規表現(半角のみ入力可)
      state.login_id = state.login_id.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i, "");
      user.storeLoginId(state.login_id);
    };
    const inputPassword = () => {
      // 正規表現(半角のみ入力可)
      state.password = state.password.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i, "");
      user.storePassword(state.password);
    };

    //メアド、パスワード未入力欄じゃないかチェック
    const checkInput = computed(
      () => state.login_id !== "" && state.password !== ""
    );

    // ログイン
    const submitLogin = async () => {
      //user.changeLoading(true);

      // 認証
      await user.login(context.root.$route.query);

      if (!user.login_status) {
        return;
      }

      // JOBPAY未登録または未アクティベートならダイアログを経由
      //if (!user.userinfo.jobpay_registered_flg || !user.userinfo.jobpay_activation_flg) {
      //  displayui.openDialogEncourageRegistration();
      //  return;      
      //}

      // 認証後の画面遷移
      routeAfterLogin();
    };
    // 認証後の画面遷移
    const routeAfterLogin = () => {
      const query:any = context.root.$route.query;

      // 規約を見ていなければ規約画面に遷移
      if (!user.checkAgreement()) {
        user.gotoTermsAgreePage(query);
        return;
      }

      // redirectパラメーターに応じてログイン直後に表示する画面の切り替えを行う
      user.gotoLoginFirstPage(query);
    };

    const submitLogout = () => {
      user.logout();
      // displayui.changeSearchComponent("SearchInputBoxMain");
    };
    // パスワードリセット画面へ遷移
    const gotoPasswordResetPage = async () => {
      router.push({
        name: "AuthPasswordUpdateMailInput",
      });
    };
    // 会員登録画面へ遷移
    const gotoRegistrationPage = async () => {
      router.push("/member");
    };

    return {
      submitLogin,
      submitLogout,
      userinfo,
      login_id,
      password,
      inputLoginId,
      inputPassword,
      routeAfterLogin,
      gotoPasswordResetPage,
      gotoRegistrationPage,
      state,
      checkInput,
      isViewRegistration,
    };
  },
  name: "login",
  components: {
    Button,
    PageTitle,
    EncourageRegistrationDialog,
  },
});
