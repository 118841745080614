





































import {
  createComponent,
  computed,
  inject,
  onMounted,
  ref,
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import Button from "@/components/atoms/button/Button.vue";

import Spacer from "@/components/molecules/Spacer.vue";

type Props = {
  company_id: string;
  job_information_id: string;
  application_status_code: string; //1221add
};

export default createComponent({
  props: {
    company_id: {
      default: "",
    },
    job_information_id: {
      default: "",
    },
    application_status_code: {
      default: "",
    },
  },
  setup(props) {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }

    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const jobdetail = computed(() => job.jobdetail);
    const applicationInfo = computed(() => user.applicationInfo);

    const applicationStatusCode = ref("アプリケーションステータスコード");

    onMounted(() => {
      user.getApplicationInfo(props.company_id, props.job_information_id);
    });

    return {
      jobdetail,
      applicationInfo,
      applicationStatusCode,
    };
  },

  components: {
    Button,
    Spacer,
  },
});
