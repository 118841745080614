






















import router from "@/router";
import {
  createComponent,
  // computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import Button from "@/components/atoms/button/Button.vue";
export default createComponent({
  components: { Button },
  name: "after-logout",

  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const leaveDrawer = displayui.leaveDrawer;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onMounted(() => {
      leaveDrawer();
      leaveHeaderSub();
      leaveHeaderMyPage();
    });
    const toLogin = () => {
      router.push(
        "/login",
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    };
    const toTop = () => {
      router.push(
        "/",
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    };

    return { toLogin, toTop };
  },
});
