




























































import {
  createComponent,
  inject,
  computed,
  onMounted,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";

import JobcategoryLabel from "@/components/atoms/text/JobcategoryLabel.vue";
import DateBox from "@/components/molecules/DateBox.vue";
import ArrowRightIconC from "@/components/atoms/icon/ArrowRightIconC.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const jobdetail = computed(() => job.jobdetail);
    const company_id = job.jobdetail.company_id;

    const rating = computed(() => job.rating);
    // const sumEvaluation = computed(() => job.sumEvaluation);
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveDrawer = displayui.leaveDrawer;

    onMounted(() => {
      // 企業の累計評価なのでcompany_idだけ渡す
      job.getRatingTotal(company_id);
    });
    return { rating };
  },

  components: { JobcategoryLabel, DateBox, ArrowRightIconC },
});
