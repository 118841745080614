





import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";

export default createComponent({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setup() {},
});
