






import { createComponent } from "@vue/composition-api";

import JobDetailView from "@/components/templates/JobDetailView.vue";

export default createComponent({
  components: {
    JobDetailView,
  },
});
