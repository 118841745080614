
























































































































































import {
  createComponent,
  inject,
  computed,
  onMounted,
  ref,
} from "@vue/composition-api";
import UserKey from "@/components/user-key";

import DisplayUIKey from "@/components/display-ui-key";
import SearchKey from "@/components/search-key";
import SelectBank from "@/components/molecules/SelectBank.vue";
import SelectBankBranch from "@/components/molecules/SelectBankBranch.vue";
import SelectBankFirstLetter from "@/components/molecules/SelectBankFirstLetter.vue";
import InputText from "@/components/atoms/input/InputText.vue";

import Button from "@/components/atoms/button/Button.vue";
import SearchForm from "@/components/atoms/input/SearchForm.vue";
import LabelText from "@/components/atoms/text/LabelText.vue";
import Spacer from "@/components/molecules/Spacer.vue";

export default createComponent({
  components: {
    SearchForm,
    LabelText,
    Button,
    InputText,
    Spacer,
  },
  setup() {
    const refs = ref(null);
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const bankAccount = computed(() => user.bankAccount);
    const detailBankFile = user.detailBankFile;
    const bank = computed(() => user.bank);

    const account_no = user.bank.account_no;
    const account_holder = user.bank.account_holder;
    const account_type = user.bank.account_type;
    var url = computed(() => user.userinfo.url);

    const inputAccountType = (e: string) => {
      user.storeAccountType(e);
    };
    const inputAccountNumber = (e: string) => {
      user.storeAccountNumber(e);
    };
    const inputAccountHolder = (e: string) => {
      user.storeAccountHolder(e);
    };

    const getBankAccount = user.getBankAccount;
    const postBankAccount = () => {
      user.postBankAccount();
    };
    const clearBankInput = () => {
      user.clearBankInput();
    };

    const changeBankComponent = displayui.changeBankComponent;
    onMounted(() => {
      // コメントアウト解除する1225
      // getBankAccount();
    });
    return {
      bank,
      bankAccount,
      getBankAccount,
      postBankAccount,
      inputAccountType,
      inputAccountNumber,
      inputAccountHolder,
      account_no,
      account_holder,
      account_type,
      changeBankComponent,
      clearBankInput,
      detailBankFile,
      url,
    };
  },
});
