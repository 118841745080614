import { render, staticRenderFns } from "./FavoriteBtn.vue?vue&type=template&id=496637d8&scoped=true&"
import script from "./FavoriteBtn.vue?vue&type=script&lang=ts&"
export * from "./FavoriteBtn.vue?vue&type=script&lang=ts&"
import style0 from "./FavoriteBtn.vue?vue&type=style&index=0&id=496637d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496637d8",
  null
  
)

export default component.exports