








































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import Button from "@/components/atoms/button/Button.vue";
import ArrowRightIconC from "@/components/atoms/icon/ArrowRightIconC.vue";

export default createComponent({
  setup() {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const jobdetail = computed(() => job.jobdetail);
    const company_id = job.jobdetail.company_id;
    const job_information_id = job.jobdetail.job_information_id;

    const attendancelist = computed(() => job.attendance);
    const getAttendance = job.getAttendance;
    onMounted(() => {
      getAttendance(company_id, job_information_id);
    });

    return { attendancelist };
  },

  components: {
    Button,
    ArrowRightIconC,
  },
});
