



import { createComponent } from "@vue/composition-api";

import LogoSvg from "@/assets/svg/logo.svg";

export default createComponent({
  components: {
    LogoSvg,
  },
});
