









































import Vue from "vue";
import { Prop, Component, Emit } from "vue-property-decorator";

import SearchIcon from "@/components/atoms/icon/SearchIcon.vue";
import CloseIcon from "@/components/atoms/icon/CloseIcon.vue";
import XmarkIcon from "@/components/atoms/icon/XmarkIcon.vue";
import ReturnIcon from "@/components/atoms/icon/ReturnIcon.vue";
import EraserIcon from "@/components/atoms/icon/EraserIcon.vue";
@Component({
  components: {
    SearchIcon,
    CloseIcon,
    XmarkIcon,
    ReturnIcon,
    EraserIcon,
  },
})
export default class Button extends Vue {
  @Prop({ type: String, default: "" }) //
  readonly btnStyle!: string;

  @Prop({ type: String, default: "" }) //ボタンサイズ
  readonly size!: string;

  @Prop({ type: String, default: "" }) //iconネーム
  readonly icon!: string;

  @Prop({ type: Boolean, default: false }) //アウトライン（flag）
  readonly outline!: boolean;

  @Prop({ type: Boolean, default: false }) //角ラウンド（flag）
  readonly round!: boolean;

  @Prop({ type: Boolean, default: false }) //ワイドフルサイズ（flag）
  readonly full!: boolean;

  @Prop({ type: Boolean, default: false }) //テキスト[フラット]（flag）
  readonly flat!: boolean;

  @Prop({ type: Boolean, default: false }) //disabledフラグ（flag）
  readonly disabled!: boolean;

  @Prop({ type: String, default: "" }) //ボタンの値
  readonly value!: string;

  @Prop({ type: String, default: "" }) //ボタンのname
  readonly name!: string;

  @Prop({ default: null }) //ボタン（セル）幅
  readonly btnWidth!: number;

  @Prop({ default: null }) //ボタン（セル）高さ
  readonly btnHeight!: number;

  @Prop({ default: null }) //ボタン（セル）フォントサイズ
  readonly btnFontSize!: number;

  @Prop({ default: null }) //ボタンpaddingTop
  readonly btnPaddingTop!: number;

  @Prop({ default: "" }) //ボタンBackGrounfColor
  readonly btnBackGroundColor!: string;

  @Emit("click")
  click(e: MouseEvent) {
    this.$emit("btnfnc");
  }

  styleObject: object = {
    width: this.btnWidth + "px",
    height: this.btnHeight + "px",
    "font-size": this.btnFontSize + "rem",
    "padding-top": this.btnPaddingTop + "px",
    "background-color": this.btnBackGroundColor,
  };
}
