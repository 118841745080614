








































































































































































































































































































































import {
  createComponent,
  inject,
  onMounted,
  computed,
} from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import RegistrationKey from "@/components/registration-key";
import _ from "lodash";
import router from "@/router";

export default createComponent({
  setup() {

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const registration = inject(RegistrationKey);
    if (!registration) {
      throw new Error(`${RegistrationKey} is not provided`);
    }
    // 画面リフレッシュされていたら登録トップページへ遷移
    if (registration.mail === "") {
       router.replace("/member");   
    }

    // 画面編集中に設定
    displayui.setEditing(true);

    const displayParams = computed(() => registration.displayParams);
    const displayError = computed(() => registration.displayError);
    const displayErrorFlag = computed(() => registration.displayErrorFlag);
    const mail = computed(() => registration.mail);

    // 性別選択用プルダウン
    const sexList = computed(() => registration.sexList);

    // 生年月日一覧
    const currentYear = new Date().getFullYear();
    const yearList = _.range(1930, currentYear + 1, +1);
    const monthList = _.map(_.range(1, 13, +1), (item: number) =>
      String(item).padStart(2, "0")
    );
    const dateList = _.map(_.range(1, 32, +1), (item: number) =>
      String(item).padStart(2, "0")
    );

    //  国籍プルダウン
    const nationalityList = computed(() => registration.nationalityList);

    // 本人確認書類の選択
    const identifyList = computed(() => registration.identifyList);

    const inputBirthday = () => {
      registration.inputBirthday();
      registration.clearDisplayError("birthday");
      // チェックは「次へ」ボタンを押した時に行う
      //registration.validateVpEntryDisplayParams("birthday");
    };
    const checkPep = (category: string) => {
      registration.checkPep();
      registration.validateVpEntryDisplayParams(category);
    };
    // フォーカスアウトの時
    const blurValue = (category: string) => {
      registration.validateVpEntryDisplayParams(category);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputValue = (event: any, category: any) => {
      registration.inputValue(event.target.value, category);
      registration.clearDisplayError(category);
    };

    // 本人確認書類提出方法選択したかどうかチェック
    const checkIdentify = () => {
      displayParams.value.identify !== "";
    };
    // スマホ,タブレット以外のデバイス
    const isMobile =
      navigator.userAgent.indexOf("iPhone") > 0 ||
      (navigator.userAgent.indexOf("Android") > 0 &&
        navigator.userAgent.indexOf("Mobile") > 0) ||
      navigator.userAgent.indexOf("BlackBerry") > 0 ||
      navigator.userAgent.indexOf("Windows Phone") > 0 ||
      navigator.userAgent.indexOf("webOS") > 0 ||
      navigator.userAgent.indexOf("iPad") > 0 ||
      navigator.userAgent.indexOf("iPod") > 0 ||
      navigator.userAgent.indexOf("Android") > 0;

    // 入力欄チェック
    const checkInput = computed(
      () => {
        return (
          !displayError.value.name1 &&
          !displayError.value.name2 &&
          !displayError.value.nameKana1 &&
          !displayError.value.nameKana2 &&
          !displayError.value.sex &&
          !displayError.value.birthday &&
          !displayError.value.zip_address &&
          !displayError.value.address1 &&
          !displayError.value.address2 &&
          !displayError.value.phone_number &&
          registration.mail
        )
    });
    // 住所検索ボタン押下
    const getAddress = async () => {
      const entrySearchAddressStatus = await registration.getZipAddress();
      // 呼び出し失敗
      if (entrySearchAddressStatus >= 400) {
        alert(registration.message);
        registration.clearMessage();
        return;
      }

      if (displayParams.value.address1 !== "") {
        registration.clearDisplayError("address1");
      }
    };
    // 確認画面への遷移
    const submitAction = async () => {
      registration.clearDisplayError("");
      // 入力チェック
      for (const prop in displayParams.value) {
        registration.validateVpEntryDisplayParams(prop);
      }
      if(displayErrorFlag.value){
        return;
      }

      // 画面編集中解除
      displayui.setEditing(false);

      router.push({
        name: "RegistrationConfirm",
      });
    };

    onMounted(async () => {
      // 初期状態で入力チェック
      //for (const prop in displayParams.value) {
      //  registration.validateVpEntryDisplayParams(prop);
      //}
    });

    return {
      mail,
      displayParams,
      displayError,
      sexList,
      yearList,
      monthList,
      dateList,
      identifyList,
      nationalityList,
      checkPep,
      checkIdentify,
      blurValue,
      inputValue,
      inputBirthday,
      checkInput,
      isMobile,
      getAddress,
      submitAction
    };
  },
});
