













import { createComponent, inject, onMounted } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import Button from "@/components/atoms/button/Button.vue";

export default createComponent({
  components: { Button },

  setup() {
    const staticUrl = process.env.VUE_APP_STATIC_SERVER;

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const leaveDrawer = displayui.leaveDrawer;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onMounted(() => {
      leaveDrawer();
      leaveHeaderSub();
      leaveHeaderMyPage();
    });
    return { staticUrl };
  },
});
