import { reactive } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";
import Filters from "@/lib/filters";

interface Response {
  status: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: Object;
}

export default function authinfoStore() {
  const state = reactive({
    loading: false,
    loadingCount: 0,
    response: <Response>{
      status: 0,
    },
    headers: <any>{
      "content-type": "application/json",
    },
    isAgree: false,
    email: "",
    message: "",

    passwordUpdateParams: {
      password: <string>""
    },
    sendResetPasswordMailParams: {
      cyurica_card_no: ""
    },
    displayPasswordUpdateErrorFlag: false,
    displayPasswordUpdateError: {
      password: <string>""
    },
    passwordStrengthProgress: <any>{
      value: 0,
      color: "error",
      text: "パスワードの安全性："
    },
  });
  const startLoading = () => {
    state.loadingCount++;
    state.loading = state.loadingCount > 0;
  };
  const stopLoading = () => {
    state.loadingCount--;
    state.loading = state.loadingCount > 0;
  };
  const CancelToken = axios.CancelToken;
  const axiosSource = <any>{};
  // バリプラ会員API呼び出し
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosInstance.defaults.timeout = 30000;
  axiosInstance.interceptors.response.use(
    function (response) {
      // 200 OK
      state.response = response;
      return response;
    },
    function (error) {
      state.response = error.response;
      // 認証エラーの場合
      if (state.response.status == 401) {
        router.push({ name: "Logout" });
      }
      // タイムアウトの場合
      const isTimeout = error.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
      }
      // ネットワークエラーの場合
      if (!error.response) {
        if (!axios.isCancel(error)) {
          // cancel以外
          router.push("/network-error");
        }
      }
      return error.response;
    }
  );

  return {
    get loading(): boolean {
      return state.loading;
    },
    get response() {
      return state.response;
    },
    get email() {
      return state.email;
    },
    get isAgree() {
      return state.isAgree;
    },
    get message() {
      return state.message;
    },
    setAuthorizationHeader(token: string) {
      state.headers.Authorization = `Bearer ${token}`;
    },

    // パスワードリセット処理用
    clearPasswordUpdateDisplayError() {
      state.displayPasswordUpdateErrorFlag = false;
      state.displayPasswordUpdateError.password = "";
    },
    clearPasswordUpdateParams() {
      state.passwordUpdateParams = {
        password: ""
      };
    },
    clearPasswordStrengthProgress() {
      state.passwordStrengthProgress.value = 0;
      state.passwordStrengthProgress.color = "error";
      state.passwordStrengthProgress.text = "パスワードの安全度：";
    },
    get passwordUpdateParams() {
      return state.passwordUpdateParams;
    },
    inputUpdatePassword(e: string) {
      state.passwordUpdateParams.password = e;
    },
    inputSendResetPasswordMailParams(e: string) {
      state.sendResetPasswordMailParams.cyurica_card_no = e;
    },
    get displayPasswordUpdateError(){
      return state.displayPasswordUpdateError 
    },
    get displayPasswordUpdateErrorFlag() {
      return state.displayPasswordUpdateErrorFlag;
    },
    validatePasswordUpdateParams() {
      this.clearPasswordUpdateDisplayError();

      const result = Filters.requiredPassword(state.passwordUpdateParams.password);
      if(result){
        state.displayPasswordUpdateErrorFlag = true;
        state.displayPasswordUpdateError.password = result['message'];
        return;
      }

      const result2 = Filters.passwordFormat(state.passwordUpdateParams.password);
      if(result2){
        state.displayPasswordUpdateErrorFlag = true;
        state.displayPasswordUpdateError.password = result2['message'];
        return;
      }
    },
    get passwordStrengthProgress() {
      return state.passwordStrengthProgress;
    },
    setPasswordStrengthProgress() {
      if (state.passwordUpdateParams.password.trim().length <= 0) {
        state.passwordStrengthProgress.value = 0;
        state.passwordStrengthProgress.color = "error";
        state.passwordStrengthProgress.text = "パスワードの安全度：";
        return;
      }

      // const result = zxcvbn(state.passwordUpdateParams.password);

      // switch (result.score) {
      //   case 4:
      //     state.passwordStrengthProgress.value = 100;
      //     state.passwordStrengthProgress.color = "green lighten-3";
      //     state.passwordStrengthProgress.text = "パスワードの安全度：最高";
      //     break;
      //   case 3:
      //     state.passwordStrengthProgress.value = 75;
      //     state.passwordStrengthProgress.color = "blue lighten-3";
      //     state.passwordStrengthProgress.text = "パスワードの安全度：高";
      //     break;
      //   case 2:
      //     state.passwordStrengthProgress.value = 50;
      //     state.passwordStrengthProgress.color = "orange lighten-3";
      //     state.passwordStrengthProgress.text = "パスワードの安全度：中";
      //     break;
      //   case 1:
      //     state.passwordStrengthProgress.value = 25;
      //     state.passwordStrengthProgress.color = "red lighten-3";
      //     state.passwordStrengthProgress.text = "パスワードの安全度：低";
      //     break;
      //   default:
      //     state.passwordStrengthProgress.value = 25;
      //     state.passwordStrengthProgress.color = "red lighten-3";
      //     state.passwordStrengthProgress.text = "パスワードの安全度：低";
      //     break;
      // }
    },
    updatePasswordWithToken(token: string) {
      startLoading();

      return axiosInstance
        .post(`/auth/password/update/submit/${token}`, state.passwordUpdateParams, {
          headers: state.headers
        })
        .then(response => {
          if (response.status < 400) {
            this.clearPasswordUpdateParams();
          }

          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },
    // パスワードリセット--メール送信API
    postPasswordResetMail() {
      startLoading();

      return axiosInstance
        .post(
          "/auth/password/update/sendmail",
          {
            mail_address: state.email,
          },
          {
            headers: state.headers,
          }
        )
        .then(response => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {
          state.message = "サーバーでエラーが発生しました";
        })
        .finally(() => stopLoading());
    },
    // パスワードリセットトークン検証
    verifyResetPasswordToken(token: string) {
      startLoading();

      return axiosInstance
        .get(`/auth/password/update/token-verify/${token}`, {
          headers: state.headers
        })
        .then(response => {
          const apiResponse = response.data;
          if(response.status >= 400){
            state.message = "サーバーでエラーが発生しました";
            if(apiResponse && apiResponse.message){
              state.message = apiResponse.message;
            }
            return response.status;
          }
          const apiResponseData = apiResponse.data;
          return response.status;
        })
        .catch(error => {})
        .finally(() => stopLoading());
    },

    inputMail(e: string) {
      state.email = e;
    },
    clearResponse() {
      state.response = {
        status: 0,
      };
    },
    clearMail() {
      state.email = "";
    },
    clearMessage() {
      state.message = "";
    },
  };
}

export type AuthinfoStore = ReturnType<typeof authinfoStore>;
