






































































































































































































































































































import {
  createComponent,
  computed,
  inject,
  ref,
  onMounted,
reactive,
} from "@vue/composition-api";
import router from "@/router";
import DisplayUIKey from "@/components/display-ui-key";
import SearchKey from "@/components/search-key";
import JobKey from "@/components/job-key";
import UserKey from "@/components/user-key";
import SelectLocationPrefecture from "@/components/molecules/SelectLocationPrefecture.vue";
import SelectDailySalary from "@/components/molecules/SelectDailySalary.vue";
import SelectJobCategory from "@/components/molecules/SelectJobCategory.vue";
import SelectFeature from "@/components/molecules/SelectFeature.vue";
import { stringify } from "qs";
export default createComponent({
  components: {
    SelectLocationPrefecture,
    SelectDailySalary,
    SelectJobCategory,
    SelectFeature,
  },
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const searching = computed(() => displayui.searching);
    const toggleSearching = displayui.toggleSearching;

    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    //loginが在るか否か
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const query:any = context.root.$route.query;

    const login_status = computed(() => user.login_status);
    const searchParams = computed(() => search.searchParams);
    const salary = computed(() => search.salary);
    const freeWord = computed(() => search.freeWord);
    const dates = computed(() => search.workDays);
    const starttime = computed(() => search.workingStartTime);
    const endtime = computed(() => search.workingEndTime);
    const jobCategorySelected = computed(() => search.jobCategorySelected);
    const jobCategoryName = computed(() => search.jobCategory.names);
    const jobCategoryCode = computed(() => search.jobCategory.codes);
    const featureSelected = computed(() => search.featureSelected);
    const feature = computed(() => search.feature);
    const featureName = computed(() => search.featureName);
    //skill
    //vselectを使うためのoption 配列
    const city_name = computed(() => search.locale.kanto_names);
    const salary_items = [
      {
        text: "￥1,000 以上",
        value: "1000",
      },
      {
        text: "￥1,100 以上",
        value: "1100",
      },
      {
        text: "￥1,200 以上",
        value: "1200",
      },
      {
        text: "￥1,300 以上",
        value: "1300",
      },
      {
        text: "￥1,400 以上",
        value: "1400",
      },
      {
        text: "￥1,500 以上",
        value: "1500",
      },
    ];

    // storeに選択したsaralyを保存
    const changeSalarySelect = (e: string) => {
      search.decideSalary(e);
    }

    const inputFreeword = (e: string) => {
      search.inputFreeword(e)
    }

    const selectDate = (workDays: string[]) => {
      search.decideWorkDays(workDays);
    }

    const decideNotWorkdays = () => {
      search.decideNotWorkdays();
    };

    const starttime_items = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];

    const changeStartTime = (e: string) => {
      search.decideWorkingStartTime(e);
    }

    const endtime_items = [
      "0:00",
      "1:00",
      "2:00",
      "3:00",
      "4:00",
      "5:00",
      "6:00",
      "7:00",
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];

    const changeEndTime = (e: string) => {
      search.decideWorkingEndTime(e);
    }

    const saveJobSearch = async() => {
      await search.saveJobSearch();
    }

    const loadJobSearch = async() => {
      await search.loadJobSearch();
    }

    //modalの設定
    const place_modal = ref<boolean>(false);
    const date_modal = ref<boolean>(false);
    const job_modal = ref<boolean>(false);
    const feature_modal = ref<boolean>(false);
    const featuremodal_close = () => (feature_modal.value = false);
    const jobmodal_close = () => (job_modal.value = false);
    const placemodal_close = () => (place_modal.value = false);
    //btnの設定
    //リセットボタン
    const clearSearchParams = search.clearSearchParams;
    //実行ボタン
    const leaveSearching = () => {
      displayui.leaveSearchingClose();
    }
    
    // 検索
    const searchByParams = async() => {
      leaveSearching();
      search.collectData();
      await job.searchjobjson(searchParams);

      // 検索完了後、トップ画面を表示
      let searchQuery = search.getSearchQueryMap();
      router.push({ name: 'top', query: searchQuery }).catch(err => {});
    };
    onMounted(async () => {
      // システム情報取得
      await search.requestSystemInfo();
    });
    return {
      searching,
      login_status,
      toggleSearching,
      salary,
      freeWord,
      dates,
      starttime_items,
      starttime,
      endtime_items,
      endtime,
      salary_items,
      jobCategoryName,
      jobCategoryCode,
      jobCategorySelected,
      feature,
      featureSelected,
      featureName,
      city_name,
      place_modal,
      date_modal,
      job_modal,
      feature_modal,
      clearSearchParams,
      leaveSearching,
      searchByParams,
      featuremodal_close,
      jobmodal_close,
      placemodal_close,
      changeSalarySelect,
      inputFreeword,
      changeStartTime,
      changeEndTime,
      selectDate,
      saveJobSearch,
      loadJobSearch,
      decideNotWorkdays
    };
  },
});
