


























































import {
  createComponent,
  inject,
  computed,
  onMounted,
  ref,
  onErrorCaptured,
  watch,
} from "@vue/composition-api";

import Loading from "@/components/templates/Loading.vue";

import DisplayUIKey from "@/components/display-ui-key";
import JobKey from "@/components/job-key";
import UserKey from "@/components/user-key";
import CloseAccountKey from "@/components/close-account-key";
import SearchKey from "@/components/search-key";
import SearchBtn from "@/components/atoms/button/SearchBtn.vue";
import router from "@/router";


import LogoIcon from "@/components/atoms/icon/LogoIcon.vue";

import Drawer from "@/components/templates/Drawer.vue";
import JobAlert from "@/components/molecules/JobAlert.vue";
import UserAlert from "@/components/molecules/UserAlert.vue";
import MainAlert from "@/components/molecules/MainAlert.vue";

import _ from "lodash";
import SearchAlert from "./SearchAlert.vue";

export default createComponent({
  components: {
    LogoIcon,
    Drawer,
    JobAlert,
    UserAlert,
    MainAlert,
    Loading,
    SearchBtn,
    SearchAlert
},
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const closeAccount = inject(CloseAccountKey);
    if (!closeAccount) {
      throw new Error(`${CloseAccountKey} is not provided`);
    }

    const toTopPage = () => {
      displayui.leaveSearchingClose();

      displayui.toTopPage(true);
    };

    const isMobile = computed(() => {
      if (
        navigator.userAgent.indexOf("iPhone") > 0 ||
        (navigator.userAgent.indexOf("Android") > 0 &&
          navigator.userAgent.indexOf("Mobile") > 0)
      ) {
        return true;
      } else {
        return false;
      }
    });

    const login_status = computed(() => user.login_status);
    const drawer = computed(() => displayui.drawer);
    const openDrawer = () => {
      displayui.leaveSearchingClose();
      displayui.openDrawer();
    }
    const leaveDrawer = () => {
      displayui.leaveSearchingClose();
      displayui.leaveDrawer();
    }
      

    const jobAlert = computed(() => job.jobAlert);
    const searchAlert = computed(() => search.searchAlert);
    const userAlert = computed(() => user.userAlert);
    const mainAlert = computed(() => displayui.mainAlert);
    const setUserAlert = user.setUserAlert;
    const setJobAlert = job.setJobAlert;
    const setMainAlert = displayui.setMainAlert;

    const loginErrorStatus = computed(() => user.loginError.status);
    const loginErrorDetail = computed(() => user.loginError.data.detail);
    const userErrorStatus = computed(() => user.userError.status);
    const jobErrorStatus = computed(() => job.jobError.status);
    const searchErrorStatus = computed(() => search.searchError.status);
    const inquiryErrorStatus = computed(() => user.inquiryError.status);

    const displayuiLoading = computed(() => displayui.loading);
    const jobLoading = computed(() => job.loading);
    const searchLoading = computed(() => search.loading);
    const userLoading = computed(() => user.loading);
    const closeAccountLoading = computed(() => closeAccount.loading);

    // const outsideDrawer = (e: any) => {
    //   setTimeout(() => {
    //     displayui.openDrawer();
    //   }, 100);
    // };

    watch(
      () => user.loginError,
      (currentError, prevError) => {
        const status = loginErrorStatus.value;
        if (status) {
          // 認証エラー(Unauthorized)401
          if (status === 401) {
            setMainAlert(status, loginErrorDetail.value, "");
            // システムエラー・サーバーエラー500
          } else if (status === 500) {
            setMainAlert(status, "サーバーエラーです", "");
            // BadRequest（パラメータエラー）400
          } else if (status === 400) {
            setMainAlert(
              status,
              "入力項目が誤っています",
              "または入力項目が空です。または不正なリロード。"
            );
            // アプリケーションでアクセス禁止(Forbidden)403
          } else if (status === 403) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
            // 認められていないメソッドアクセスの場合のエラー(Method Not Allowed)405
          } else if (status === 405) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
          }
        }
      }
    );

    watch(
      () => user.userError,
      (currentError, prevError) => {
        const status = user.userError.status;
        const statusText = user.userError.statusText;
        let message = "";
        try{
          message = "(" + user.userError.data.detail + ")";
        } catch(e){
          // empty
        }
        if (status) {
          // 認証エラー(Unauthorized)401
          if (status === 401) {
            // API側でログアウト検出した場合画面もログアウト状態に設定
            user.logoutForActivate();
            router.push("/");
            // システムエラー・サーバーエラー500
          } else if (status === 500) {
            setMainAlert(status, "サーバーエラーです", "");
            // BadRequest（パラメータエラー）400
          } else if (status === 400) {
            setMainAlert(
              status,
              "入力項目が誤っています",
              "または入力項目が空です。または不正なリロード。" + message
            );
            // アプリケーションでアクセス禁止(Forbidden)403
          } else if (status === 403) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
            // 認められていないメソッドアクセスの場合のエラー(Method Not Allowed)405
          } else if (status === 405) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
          }
        }
      }
    );

    watch(
      () => job.jobError,
      (currentError, prevError) => {
        const status = jobErrorStatus.value;
        if (status) {
          // 認証エラー(Unauthorized)401
          if (status === 401) {
            // API側でログアウト検出した場合画面もログアウト状態に設定
            user.logoutForActivate();
            router.push("/");
            // システムエラー・サーバーエラー500
          } else if (status === 500) {
            setMainAlert(status, "サーバーエラーです", "");
            // BadRequest（パラメータエラー）400
          } else if (status === 400) {
            setMainAlert(
              status,
              "入力項目が誤っています",
              "または入力項目が空です。または不正なリロード。"
            );
            // アプリケーションでアクセス禁止(Forbidden)403
          } else if (status === 403) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
            // 認められていないメソッドアクセスの場合のエラー(Method Not Allowed)405
          } else if (status === 405) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
          }
        }
      }
    );

    watch(
      () => search.searchError,
      (currentError, prevError) => {
        const status = searchErrorStatus.value;
        if (status) {
          // 認証エラー(Unauthorized)401
          if (status === 401) {
            router.push("/");
            // システムエラー・サーバーエラー500
          } else if (status === 500) {
            setMainAlert(status, "サーバーエラーです", "");
            // BadRequest（パラメータエラー）400
          } else if (status === 400) {
            setMainAlert(
              status,
              "入力項目が誤っています",
              "または入力項目が空です。または不正なリロード。"
            );
            // アプリケーションでアクセス禁止(Forbidden)403
          } else if (status === 403) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
            // 認められていないメソッドアクセスの場合のエラー(Method Not Allowed)405
          } else if (status === 405) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
          }
        }
      }
    );

    watch(
      () => user.inquiryError,
      (currentError, prevError) => {
        const status = inquiryErrorStatus.value;
        if (status) {
          // システムエラー・サーバーエラー500
          if (status === 500) {
            setMainAlert(status, "サーバーエラーです", "");
            // BadRequest（パラメータエラー）400
          } else if (status === 400) {
            // user.tsでエラー出力するのでここでは何もしない
          } else if (status === 403) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
            // 認められていないメソッドアクセスの場合のエラー(Method Not Allowed)405
          } else if (status === 405) {
            setMainAlert(status, "アクセスできません", "");
            router.push("/access-error");
          }
        }
      }
    );
    const searching = computed(() => displayui.searching);
    const toggleSearching = displayui.toggleSearching;
    const notice_count = computed(() => user.notice_count);

    onMounted(() => {
      user.checkLoginStatus();
      user.requestNoticeCount();
    });

    // ログイン後に通知件数取得API叩く
    watch(() => login_status.value, () => user.requestNoticeCount());

    return {
      toTopPage,
      drawer,
      openDrawer,
      login_status,
      jobAlert,
      searchAlert,
      userAlert,
      mainAlert,
      displayuiLoading,
      jobLoading,
      searchLoading,
      userLoading,
      closeAccountLoading,
      isMobile,
      toggleSearching,
      leaveDrawer,
      searching,
      notice_count,
    };
  },
});
