








































import {
  createComponent,
  computed,
  inject,
  onMounted,
  reactive,
  toRefs
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

export default createComponent({
  props: {
    company_id: {
      type: String,
      required: true
    },
    job_information_id: {
      type: String,
      required: true
    }
  },
  setup(props,context) {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const state = reactive({
      iineItem: [1, 1, 1] as Array<number>,
    });

    const rating = computed(() => job.rating);
    const myrating = computed(() => job.myrating);

    const postMyRating = (company_id: string, job_information_id: string) => {
      job.postMyRating(company_id, job_information_id, state.iineItem);
    };

    const ing_rating = computed(() => job.ing_rating);
    const storeRating = (e: string) => {
      job.storeRating(e);
    };
    const ing_comment = computed(() => job.ing_comment);
    const storeComment = (e: string) => {
      job.storeComment(e);
    };

    // const clearTextBox = job.clearTextBox;
    const clearTextBox = function () {
      state.iineItem = [1, 1, 1];
    };
    const {company_id, job_information_id} = toRefs(props);
    onMounted(async () => {
      await job.getMyRating(company_id.value, job_information_id.value).then(() => {
        if (job.myrating) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          state.iineItem = [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            job.myrating.evaluation_code,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            job.myrating.evaluation_code_2,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            job.myrating.evaluation_code_3,
          ];
        } else {
          clearTextBox();
        }
      });
    });

    const evaluation_name_list = [
      process.env.VUE_APP_EVALUATION_NAME1,
      process.env.VUE_APP_EVALUATION_NAME2,
      process.env.VUE_APP_EVALUATION_NAME3,
    ];

    const changeIine = function (index: number, value: number) {
      state.iineItem[index] = value;
      state.iineItem = state.iineItem.slice(0, state.iineItem.length);
    };
    const myRatingData = job.myrating;

    const parentCancel = ()=>{
      context.emit('parentCancel');
    }
    return {
      rating,
      myrating,
      ing_rating,
      ing_comment,
      postMyRating,
      storeRating,
      storeComment,
      clearTextBox,
      evaluation_name_list,
      state,
      changeIine,
      myRatingData,
      dialog: false,
      parentCancel
    };
  },
});
