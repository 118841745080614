





















































import {
  createComponent,
  inject,
  computed,
  onMounted,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";

import JobcategoryLabel from "@/components/atoms/text/JobcategoryLabel.vue";
import DateBox from "@/components/molecules/DateBox.vue";
import FavoriteBtn from "@/components/atoms/button/FavoriteBtn.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }

    const favoritelist = computed(() => user.favoritelist);
    const leaveSearching = displayui.leaveSearching;
    const getFavoriteListJson = user.getFavoriteJson;

    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveDrawer = displayui.leaveDrawer;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onMounted(() => {
      leaveSearching();
      leaveHeaderSub();
      leaveHeaderMyPage();
      leaveDrawer();
      getFavoriteListJson();
    });

    const postFavorite = (company_id: string, job_information_id: string) => {
      user.postFavorite(company_id, job_information_id);
    };
    const deleteFavorite = (company_id: string, job_information_id: string) => {
      user.deleteFavorite(company_id, job_information_id);
    };

    return { favoritelist, postFavorite, deleteFavorite };
  },
  components: { JobcategoryLabel, DateBox, FavoriteBtn },
});
