














import {
  createComponent,
  inject,
  computed,
  onMounted,
} from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";
import SearchKey from "@/components/search-key";

import Button from "@/components/atoms/button/Button.vue";
import CloseBtn from "@/components/atoms/button/CloseBtn.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const searchAlert = computed(() => search.searchAlert);
    const resetSearchAlert = search.resetSearchAlert;

    onMounted(() => {
      setTimeout(resetSearchAlert, 3000);
    });

    return {
      searchAlert,
      resetSearchAlert,
    };
  },

  components: {
    Button,
    CloseBtn,
  },
});
