






















































import {
computed,
  createComponent, inject, onMounted, reactive, toRefs, watch,
} from "@vue/composition-api";
import DoRating from "@/components/molecules/DoRating.vue";
import JobKey from "@/components/job-key";
import axios from "axios";

export default createComponent({
  props: {
    company_id: {
      type: String,
      required: true
    },
    job_information_id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }

    const state = reactive({
      isAttendance: false,
      myrating: false,
      job_information_id:"",
      company_id: ""
    });

    const axiosinstance = axios.create({
      baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
    });
    // 勤怠状況 GET
    const getAttendance = async(company_id: string, job_information_id: string) => {
      if (!company_id) {
        return;
      }
      if (!localStorage.getItem("token")) {
        return;
      }
      return await axiosinstance
        .get("jobpayAttendance", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: company_id,
            job_information_id: job_information_id,
          },
        })

        .then(response => {
          return response.data[0];
        })
    };

    // 自分の企業評価一覧を取得する
    const getMyRating = async(company_id: string, job_information_id: string) => {
      if (!localStorage.getItem("token")) {
        return;
      }
      return await axiosinstance
        .get("member/job-rating/myrating", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: company_id,
            job_information_id: job_information_id,
          },
        })

        .then((response) => {
          return response.data;
        })
    };

    const {company_id, job_information_id} = toRefs(props);
    watch([company_id, job_information_id], 
      async() => {
        const attendance = await getAttendance(company_id.value, job_information_id.value);
        state.myrating = await getMyRating(company_id.value, job_information_id.value);
        // 応募履歴のidと勤怠のidが一致する場合はボタン表示する
        state.isAttendance = company_id.value == attendance?.company_id && job_information_id.value === attendance.job_information_id;
        state.company_id = company_id.value;
        state.job_information_id = job_information_id.value;
      }
    );


    return {
      dialog: false,
      state
    };
  },
  components: {
    DoRating,
  }
});
