

















import { createComponent, computed, inject } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import Button from "@/components/atoms/button/Button.vue";
import router from "@/router";

import { LOGIN_LINK_SETTINGS } from "@/constants/pageview";

export default createComponent({
  components: { Button },
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const leaveDrawer = displayui.leaveDrawer;
    const login_status = computed(() => user.login_status);

    const currentPath = router.currentRoute.path;

    // 現在の画面のログインリンク設定を取得
    const loginLinkSetting = LOGIN_LINK_SETTINGS.filter(
      (loginLinkSettingSearch) => {
        let regexp = new RegExp("^" + loginLinkSettingSearch.page_url + "(#[a-zA-Z0-9_-]+)?" + "$")
        if( regexp.test(currentPath) ){
          return true;
        }
        return false;
      }
    )[0];
    // ログインリンク設定があれば処理する
    let loginLinkExists = false;
    let loginLinkParams: { [key: string]: string } = {};
    const loginLinkParamsBuf: { [key: string]: string } = {};
    if (loginLinkSetting) {
      loginLinkSetting["param_names"].forEach(function (linkParamName) {
        const paramValue = context.root.$route.query[linkParamName];
        if (paramValue === undefined) {
          return true;
        }
        loginLinkParamsBuf[linkParamName] = String(paramValue);
      });

      loginLinkParams["redirect"] = loginLinkSetting["page_url"];
      loginLinkParams = Object.assign(loginLinkParams, loginLinkParamsBuf);
    }

    return {
      leaveDrawer,
      login_status,
      currentPath,

      loginLinkParams,
    };
  },
});
