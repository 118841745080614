
































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import UserKey from "@/components/user-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SelectBankHeaderText from "@/components/atoms/text/SelectBankHeaderText.vue";
import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";

export default createComponent({
  setup() {
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const bank = user.bank;
    const banklist = computed(() => user.banklist);
    const getBankAccount = user.getBankAccount;
    const bankjson = user.bankjson;
    const refinedlist = computed(() => user.refinedlist);
    const refinedLetter = computed(() => user.refinedLetter);

    onMounted(() => {
      bankjson();
      getBankAccount();
    });
    const decideBank = (code: string, name: string) => {
      user.decideBank(code, name);
      displayui.changeBankComponent("SelectBankBranch");
    };

    return {
      bank,
      banklist,
      getBankAccount,
      bankjson,
      decideBank,
      refinedlist,
      refinedLetter,
    };
  },

  components: {
    Button,
    SelectBankHeaderText,
  },
});
