


































































import {
  createComponent,
  inject,
  onMounted,
  computed,
  reactive,
  SetupContext,
} from "@vue/composition-api";
import ProvisionalKey from "@/components/provisional-key";
import RegistrationKey from "@/components/registration-key";
import DisplayUIKey from "@/components/display-ui-key";
import router from "@/router";
export default createComponent({
  setup() {
    const state = reactive({
      email: "",
    });

    const provisionalStore = inject(ProvisionalKey);
    if (!provisionalStore) {
      throw new Error(`${ProvisionalKey} is not provided`);
    }
    const registration = inject(RegistrationKey);
    if (!registration) {
      throw new Error(`${RegistrationKey} is not provided`);
    }

    provisionalStore.clearMail();
    provisionalStore.clearResponse();
    const response = computed(() => provisionalStore.response);
    // 同意画面からの遷移以外(url直叩き)のときは同意画面に遷移させる
    if (provisionalStore.isVpTermsAgreeRequired && !provisionalStore.isVpTermsAgree) {
      router.push({
        name: "RegistrationIndex",
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayui: any = inject(DisplayUIKey);
    const leaveDrawer = displayui.leaveDrawer;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    const canGoBack = window.history.state.canGoBack;

    onMounted(() => {
      leaveDrawer();
      leaveHeaderSub();
      leaveHeaderMyPage();
    });

    //入力欄チェック
    const checkEmail = computed(
      () => state.email.match(mailRegex) && inputMail
    );

    // メアドが入力されているか判定
    const inputMail = () => {
      provisionalStore.inputMail(state.email);
      return state.email !== "";
    };

    // エントリー開始
    const postEntryStart = async () => {
      // エントリー開始API呼び出し
      const entryStartResultStatus = await provisionalStore.postEntryStart();
      // 呼び出し失敗
      if (entryStartResultStatus >= 400) {
        alert(provisionalStore.message);
        provisionalStore.clearMessage();
        return;
      }

      // 入力フォームクリア
      registration.clearDisplayParams();
      
      // 次画面に遷移
      router.push({
        name: "RegistrationInputAuthcode",
        query: { email: state.email },
      });
    };

    // メアドチェック用正規表現
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return {
      state,
      provisionalStore,
      checkEmail,
      mailRegex,
      inputMail,
      postEntryStart,
      canGoBack
    };
  },
});
