// ログイン画面を経由してリダイレクトする画面の設定
// ここに設定した画面のみリダイレクトする。パラメーターも引き継ぐ場合、param_namesに設定する
export const LOGIN_LINK_SETTINGS = [
  // 案件詳細
  {
    page_name: "job-detail",
    page_url: "/job-detail",
    param_names: [
      "company_id",
      "common_id",
      "job_information_id",
      "cpi",
      "cmi",
      "jii",
      "shift",
    ],
  },
  // 応募履歴(カレンダー)
  {
    page_name: "job-calendar",
    page_url: "/job-calendar",
    param_names: []
  },
  // マイページ
  {
    page_name: "my-page",
    page_url: "/my-page",
    param_names: []
  },
  // 通知一覧
  {
    page_name: "notice-list",
    page_url: "/notice-list",
    param_names: []
  },
  // リコメンド設定画面
  {
    page_name: "recommend",
    page_url: "/recommend",
    param_names: []
  },
  // 退会画面
  {
    page_name: "account-close",
    page_url: "/account/close/input",
    param_names: []
  },
  // JOBPAY連携
  {
    page_name: "account-connect-jobpay",
    page_url: "/account/connect/jobpay",
    param_names: []
  },
];
