var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"notice-container"},[_c('div',{staticClass:"notice-content"},[_c('div',{staticClass:"title-header"},[_c('PageTitle',{staticClass:"page-title"},[_vm._v("通知一覧")])],1),_c('div',{staticClass:"summary"},[(_vm.notice_list.length > 0)?_c('div',{staticStyle:{"padding":"10px 0px 30px 0px"}},[_c('v-btn',{attrs:{"btnWidth":35},on:{"click":function($event){return _vm.confirmNoticeReadAll()}}},[_vm._v(" 全て既読にする ")])],1):_vm._e(),_c('CommonConfirmDialog',{attrs:{"okFunction":_vm.setNoticeReadAll}}),_c('hr',{staticStyle:{"margin-bottom":"20px"}}),(_vm.notice_list.length == 0)?_c('div',[_vm._v("通知はありません。")]):_vm._e(),_vm._l((_vm.notice_list),function(notice_item){return _c('div',{key:notice_item.notice_id,staticStyle:{"padding-bottom":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.formatDate(notice_item.notice_time, true)))]),_c('span',{staticClass:"notice-item-read"},[(notice_item.notice_status == 0)?_c('span',{staticStyle:{"color":"#ee3333","font-weight":"bold"}},[_vm._v("未読")]):_vm._e(),(notice_item.notice_status == 1)?_c('span',{staticStyle:{"color":"#aaaaaa"}},[_vm._v("既読")]):_vm._e()]),_c('div',{staticClass:"notice-item-summary"},[_c('div',{staticStyle:{"padding-bottom":"5px"}},[_c('router-link',{attrs:{"to":{
                  path: '/notice-item',
                  query: { notice_id: notice_item.notice_id },
                }}},[_vm._v(_vm._s(notice_item.title))])],1),_c('div',{staticStyle:{"display":"none"},domProps:{"innerHTML":_vm._s(notice_item.message_oneline_html)}})]),_c('hr')])}),(_vm.notice_list.length > 0 && _vm.notice_page_max_no > 1)?_c('div',{staticStyle:{"text-align":"center"}},[(_vm.notice_page_no > 1)?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(_vm.notice_page_no - 1)}}},[_vm._v(" << ")]):_vm._e(),_vm._l((_vm.notice_page_max_no),function(loop_no){return _c('span',{key:loop_no},[(loop_no == _vm.notice_page_no)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(loop_no)+" ")]):_vm._e(),(loop_no != _vm.notice_page_no && loop_no == 1)?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(loop_no)}}},[_vm._v(" "+_vm._s(loop_no)+" ")]):_vm._e(),(
                loop_no == _vm.notice_page_no - 1 &&
                loop_no > 1 &&
                loop_no < _vm.notice_page_max_no
              )?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(loop_no)}}},[_vm._v(" "+_vm._s(loop_no)+" ")]):_vm._e(),(
                loop_no == _vm.notice_page_no + 1 &&
                loop_no > 1 &&
                loop_no < _vm.notice_page_max_no
              )?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(loop_no)}}},[_vm._v(" "+_vm._s(loop_no)+" ")]):_vm._e(),(
                loop_no == _vm.notice_page_no + 2 &&
                loop_no > 1 &&
                loop_no < _vm.notice_page_max_no
              )?_c('span',[_vm._v(" ... ")]):_vm._e(),(
                loop_no == _vm.notice_page_no - 2 &&
                loop_no > 1 &&
                loop_no < _vm.notice_page_max_no
              )?_c('span',[_vm._v(" ... ")]):_vm._e(),(
                loop_no != _vm.notice_page_no && loop_no == _vm.notice_page_max_no
              )?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(loop_no)}}},[_vm._v(" "+_vm._s(loop_no)+" ")]):_vm._e()])}),(_vm.notice_page_no < _vm.notice_page_max_no)?_c('a',{on:{"click":function($event){return _vm.gotoNoticeItemPage(_vm.notice_page_no + 1)}}},[_vm._v(" >> ")]):_vm._e()],2):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }