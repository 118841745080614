











































import { createComponent, inject, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

export default createComponent({
  props: {
    handleFunction: {
      type: Function,
    },
  } as const,
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const confirmDialog = computed(() => displayui.dialogEncourageRegistration);

    const closeDialogEncourageRegistration = () => {
      displayui.closeDialogEncourageRegistration();
      if (props.handleFunction) {
        props.handleFunction();
      }
    };
    return {
      confirmDialog,
      closeDialogEncourageRegistration,
      user
    };
  },
  components: {},
});
