












































































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import UserIcon from "@/components/atoms/icon/UserIcon.vue";
import CompanyIcon from "@/components/atoms/icon/CompanyIcon.vue";
import RefleshIcon from "@/components/atoms/icon/RefleshIcon.vue";

export default createComponent({
  props: {
    applicationStatusCode: String,
  },
  setup() {
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    const jobdetail = computed(() => job.jobdetail);
    const company_id = job.jobdetail.company_id;
    const job_information_id = computed(() => job.jobdetail.job_information_id);
    const common_id = computed(() => job.jobdetail.common_id);

    const ing_message = computed(() => job.ing_message);
    const storeMessage = (e: string) => {
      job.storeMessage(e);
    };
    const createChatHtml = (text: string, userFlg: number) => {
      if (userFlg != 1) {
        return text;
      }
      var a = text.split(/(https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+)/i);
      var vnodes = a.map(function (value: string, index: number) {
        if (index % 2) {
          return '<a href="' + value + '" target="_blank">' + value + "</a>";
        } else {
          return escapeHtml(value);
        }
      });
      return vnodes.join("");
    };
    const escapeHtml = (str: string) => {
      str = str.replace(/&/g, "&amp;");
      str = str.replace(/>/g, "&gt;");
      str = str.replace(/</g, "&lt;");
      str = str.replace(/"/g, "&quot;");
      str = str.replace(/'/g, "&#x27;");
      str = str.replace(/`/g, "&#x60;");
      return str;
    };
    const chat = computed(() => job.chat);
    const chatJustBefore = computed(() => job.chatJustBefore);
    const getChat = job.getChat;
    onMounted(() => {
      getChat(company_id, job.jobdetail.job_information_id);
    });

    const postChat = (
      company_id: string,
      job_information_id: string,
      message: string,
      common_id: string
    ) => {
      job.postChat(company_id, job_information_id, message, common_id);
    };

    return {
      company_id,
      job_information_id,
      chat,
      chatJustBefore,
      ing_message,
      createChatHtml,
      postChat,
      getChat,
      storeMessage,
      common_id,
    };
  },

  components: {
    UserIcon,
    CompanyIcon,
    RefleshIcon,
  },
});
