<template>
  <div id="v-app">
    <div class="d-flex justify-space-between">
      <v-tabs
        v-model="tab"
        hide-slider
        fixed-tabs
        height="41"
        class="pb-2 ma-0"
      >
        <v-tab>
          リスト
        </v-tab>
        <v-tab>
          カレンダー
        </v-tab>
      </v-tabs>
      <v-btn
          depressed
          class="ml-2 to-this-month px-6"
          @click="setToday"
          v-if="tab == 1"
        >
          今月へ
      </v-btn>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <ApplicationHistory />
      </v-tab-item>
      <v-tab-item>
        <v-sheet>
          <v-toolbar flat color="white" class="calendar-title">
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title class="year-and-month">{{ title }}</v-toolbar-title>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="jobCalendar"
            :event-color="getEventColor"
            :now="today"
            @click:event="showEvent"
            @change="updateRange"
            locale="ja-jp"
            :day-format="dayFormat"
            :show-month-on-first="false"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
          >
            <v-card color="grey lighten-4" width="300px" flat>
              <v-toolbar dark
                color="#838383"
                flat
                dense>
                <v-toolbar-title>応募状況</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="selectedOpen = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <div class="application-status">
                <!-- 004009は旧承諾キャンセル改修後→採用辞退として使用、過去データ考慮：004008は採用として表示 -->
                  {{
                    selectedEvent.public_flg === 3
                      ? "キャンセル"
                      : selectedEvent.application_status_code === "004001"
                      ? "応募中"
                      : selectedEvent.application_status_code === "004002"
                      ? "採用"
                      : selectedEvent.application_status_code === "004003"
                      ? "不採用"
                      : selectedEvent.application_status_code === "004004"
                      ? "応募キャンセル済"
                      : selectedEvent.application_status_code === "004005"
                      ? "採用辞退"
                      : selectedEvent.application_status_code === "004006"
                      ? "勤務完了"
                      : selectedEvent.application_status_code === "004007"
                      ? "欠勤"
                      : selectedEvent.application_status_code === "004008"
                      ? "採用"
                      : selectedEvent.application_status_code === "004009"
                      ? "採用辞退"
                      : selectedEvent.application_status_code === "004010"
                      ? "企業キャンセル"
                      : "不明"
                  }}
                </div>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="primary"
                  depressed
                  @click="
                    goJobDetail(
                      selectedEvent.company_id,
                      selectedEvent.common_id,
                      selectedEvent.job_information_id
                    )
                  "
                >
                  詳細画面へ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  createComponent,
  inject,
  onMounted,
  computed,
} from "@vue/composition-api";
import JobKey from "@/components/job-key";
import router from "@/router";
import axios from "axios";
import ApplicationHistory from "@/components/molecules/ApplicationHistory.vue";

export default {
  data: () => ({
    focus: "",
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    today: "",
    events: [],
    jobCalendar: [],
    tab: null,
  }),
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = start.month + "月";
      const startYear = start.year + "年";
      return `${startYear} ${startMonth} `;
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  mounted() {
    this.getToday();
    this.getCalenderData();
  },
  methods: {
    getToday() {
      var todayRen = new Date();
      this.today =
        todayRen.getFullYear() +
        "-" +
        (todayRen.getMonth() + 1) +
        "-" +
        todayRen.getDate();
    },
    getCalenderData() {
      if (!this.start && !this.end) {
        return;
      }
      const axiosinstance = axios.create({
        baseURL: process.env.VUE_APP_API_SERVER,
      });
      axiosinstance
        .get(
          `customer/v0/applicationHistoryCalendar?start_work_day=${this.start?.date}&end_work_day=${this.end?.date}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.jobCalendar = response.data;
        });
    },
    goJobDetail(company_id, common_id, job_id) {
      router.push(`/job-detail?cpi=${company_id}&jii=${job_id}`);
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = this.events;
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      this.start = start;
      this.end = end;
      this.getCalenderData();
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    dayFormat: function(date){
      return new Date(date.date).getDate()
    },
  },
  components: {
    ApplicationHistory,
  }
};
</script>
<style scoped>
.application-status {
  background-color: #BABABA;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  display: inline-block;
}
.to-this-month {
  height: 41px!important;
  background-color: #ECF8F8!important;
  color: #54595F;
  font-size: 12px;
}
.year-and-month {
  font-size: 16px!important;
  color: #54595F;
}
</style>
