








































































































































































































































































































































































































































































































































































import {
  createComponent,
  computed,
  ref,
  inject,
  onMounted,
  onUnmounted,
  reactive,
} from "@vue/composition-api";
import ReqiredLogin from "@/components/templates/RequiredLogin.vue";
import SearchKey from "@/components/search-key";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import router from "@/router";
import SubTitle from "@/components/atoms/text/SubTitle.vue";

import Button from "@/components/atoms/button/Button.vue";
import SearchHeaderText from "@/components/atoms/text/SearchHeaderText.vue";
import Spacer from "@/components/molecules/Spacer.vue";

import ApplicationHistory from "@/components/molecules/ApplicationHistory.vue";
import ApplicationCheck from "@/components/molecules/ApplicationCheck.vue";
import EvaluationDialog from "@/components/molecules/EvaluationDialog.vue";
import Attendance from "@/components/molecules/Attendance.vue";
import Chat from "@/components/molecules/Chat.vue";
import RatingList from "@/components/molecules/RatingList.vue";
import MyRating from "@/components/molecules/MyRating.vue";

import FavoriteBtn from "@/components/atoms/button/FavoriteBtn.vue";
import FavoriteBtnGray from "@/components/atoms/button/FavoriteBtnGray.vue";
import EncourageRegistrationDialog from "./EncourageRegistrationDialog.vue";

export default createComponent({
  setup(props, context) {
    const state = reactive({
      select_working_date: "",
      select_working_start_time: "",
      select_job: {
        start_time: "",
      },
      entryBtn: true,
      isRecruitmentChangeEnabled: true,
      isPenalty: false,
      penalty_message: "できません\n※キャンセルペナルティが規定数に達したため",
      isSameDateAdoption: true,
    });

    const isMobile = computed(() => {
      if (
        navigator.userAgent.indexOf("iPhone") > 0 ||
        (navigator.userAgent.indexOf("Android") > 0 &&
          navigator.userAgent.indexOf("Mobile") > 0)
      ) {
        return true;
      } else {
        return false;
      }
    });

    const SYSTEM_OUTAGE_PENALTY_POINT = 5;

    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const jobdetail = computed(() => job.jobdetail);

    // URLパラメータ取得(company_id(cpi)/common_id(cmi)/job_information_id(jii))
    let param_company_id: string = context.root.$route.query.company_id
      ? String(context.root.$route.query.company_id)
      : context.root.$route.query.cpi
      ? String(context.root.$route.query.cpi)
      : "";
    let param_common_id: string = context.root.$route.query.common_id
      ? String(context.root.$route.query.common_id)
      : context.root.$route.query.cmi
      ? String(context.root.$route.query.cmi)
      : "";
    let param_job_information_id: string = context.root.$route.query
      .job_information_id
      ? String(context.root.$route.query.job_information_id)
      : context.root.$route.query.jii
      ? String(context.root.$route.query.jii)
      : "";
    // シフト初期選択
    let param_shift: string = context.root.$route.query.shift
      ? String(context.root.$route.query.shift)
      : "";

    let company_id = job.jobdetail.company_id
      ? job.jobdetail.company_id
      : param_company_id;
    let common_id = param_common_id;
    let job_information_id = job.jobdetail.job_information_id
      ? job.jobdetail.job_information_id
      : param_job_information_id;

    job.setViewJobKey(param_company_id, common_id);

    const rating = computed(() => job.rating);
    const myrating = computed(() => job.myrating);
    const applicationInfo = computed(() => user.applicationInfo);
    const attendance = computed(() => job.attendance);
    const dialog = computed(() => user.dialog);
    const card_number = user.userinfo.card_number;
    const systeminfo = computed(() => user.systeminfo);
    const searchParams = computed(() => search.searchParams);
    const openedShiftPanels = ref([0]);

    const searchCompanyJob = async() => {
      displayui.leaveSearchingClose();

      // スクロールはクリア
      job.clearViewJobKey();

      // 検索完了後、トップ画面を表示
      router.push({ name: 'top', query: { cpi: company_id } })
    };

    // 応募
    const postApplication = async (
      company_id: string,
      job_information_id: string
    ) => {
      await ckEntryBtn();
      if (!state.entryBtn) {
        return;
      }

      // JOBPAY未登録または未アクティベートならダイアログを経由
      //if (!user.userinfo.jobpay_registered_flg || !user.userinfo.jobpay_activation_flg) {
      //  displayui.openDialogEncourageRegistration();
      //  return;
      //}
      
      execPostAppcation();
    };

    const execPostAppcation = async () => {
      await user.getBankAccount().then(() => {
        if (
          user.bankAccount.account_no == null ||
          user.bankAccount.account_no == ""
        ) {
          alert("応募前にマイページより銀行口座情報と顔写真をご登録ください。");
          router.push("/my-page");
          return;
        }
        user.postApplication(
          company_id,
          job_information_id,
          job.jobdetail.shift_id
        );
      });
    };

    const patchApplication = async (
      company_id: string,
      job_information_id: string,
      application_status_code: string
    ) => {
      // 最新状態チェック
      await ckEntryBtn();
      // 採用辞退(旧承諾キャンセル)の場合は時間制限あり
      // 応募キャンセルは時間制限なし
      if (application_status_code === "004009") {
        await job.decideJob(company_id, job_information_id);
        if (job.jobdetail.penalty_point > 0) {
          user.dialog.result = confirm(
            user.dialog.title + "\n" + user.dialog.message
          );
          if (!user.dialog.result) {
            return;
          }
        }
      }
      await user.patchApplication(
        company_id,
        job_information_id,
        application_status_code,
        job.jobdetail.shift_id,
        job.jobdetail.penalty_point
      );
      await ckEntryBtn();
    };

    // シフト情報取得
    const resolveJobShiftInfo = async (company_id:any, job_information_id:any) => {
      await job.getRatingTotal(company_id);
      await job.getMyRating(company_id, job_information_id);
      await user.getApplicationInfo(company_id, job_information_id);
      await job.getChat(company_id, job_information_id);
      getFavoriteJson();
      // 採用済み案件チェック
      await user.getApplicationSameDateFlag(company_id, job_information_id);
      // 採用済み案件と被っているか判定
      checkSameDateAdoption();
    };

    const getFavoriteJson = user.getFavoriteJson;
    const favoritelist = computed(() => user.favoritelist);
    const favoriteIds = computed(() => user.favoriteIds);
    const postFavorite = (company_id: string, job_information_id: string) => {
      user.postFavorite(company_id, job_information_id);
    };
    const deleteFavorite = (company_id: string, job_information_id: string) => {
      user.deleteFavorite(company_id, job_information_id);
    };
    const jobPostTag = computed(() => job.jobPostTag);
    const login_status = computed(() => user.login_status);

    onUnmounted(async () => {
      window.removeEventListener("popstate", handlePopstate);
    });

    onMounted(async () => {
      window.addEventListener("popstate", handlePopstate);
      await init();
    });

    // 進む・戻るボタンで画面遷移
    const handlePopstate = async function(){
      await init();
    }

    // 画面初期処理
    const init = async function(){
      // データ取得が終わるまで画面非表示
      document.getElementById("job-detail-container")!.style.visibility = "hidden";

      displayui.closeDialogEncourageRegistration();
      displayui.leaveHeaderMyPage();

      // 案件シフト指定(company_id/common_id(任意)/job_information_id)、シフト選択不可
      if (param_company_id && param_job_information_id) {
        displayui.onHeaderSub();
        displayui.onIsDetail();
        displayui.onHeaderSub();
        company_id = param_company_id;
        common_id = param_common_id;
        job_information_id = param_job_information_id;
        if (company_id == "" || job_information_id == "") {
          await router.push("/");
          return;
        }
        await job.decideJob(company_id, job_information_id, "");
      }
      // 案件指定(company_id/common_id)、シフト選択可
      else if (param_company_id && param_common_id) {
        displayui.onHeaderSub();
        displayui.onIsDetail();
        displayui.onHeaderSub();
        company_id = param_company_id;
        common_id = param_common_id;
        job_information_id = param_job_information_id;
        if (company_id == "") {
          await router.push("/");
          return;
        }
        await job.decideJob(company_id, "", common_id, param_shift);
      }
      // トップページから遷移(URLに情報なし、job.jobdetailに案件情報格納済)
      else {
      }

      // 取得した案件情報から各種情報を取得
      company_id = job.jobdetail.company_id;
      common_id = job.jobdetail.common_id;
      job_information_id = job.jobdetail.job_information_id;
      if (company_id == "") {
        await router.push("/");
        return;
      }

      await job.jobHTML(company_id, common_id, job_information_id);

      job.getAttendance(company_id, job_information_id);

      await user.getApplicationPenalty();

      // シフト情報取得
      await resolveJobShiftInfo(company_id, job_information_id);

      // 初期選択のデータの値を格納
      state.select_working_date = job.jobdetail.work_start_date;
      state.select_working_start_time = job.jobdetail.shift_list
        ? job.jobdetail.shift_list[0].start_time
        : job.jobdetail.start_time;
      
      await ckEntryBtn();
      user.dialog.title = "警告";
      user.dialog.message =
        "ペナルティポイントがついてしまいます！\n" +
        "本当に採用辞退してもよろしいですか？\n" + 
        "\n" + 
        "勤務48~24時間前に採用辞退:1p\n" +
        "勤務24~12時間前に採用辞退:3p\n" +
        "勤務12時間前以降に採用辞退:5p\n" +
        "\n" + 
        "5p蓄積されるとサービスが利用できなくなります。\n" +
        "※現在のペナルティポイントについてはお問い合わせください";

      // TODO:jobdetail.shift_idがundefinedになる場合があるため別の変数から補完
      job.jobdetail.shift_id = job.jobShiftId;

      // shiftRadioのラジオボタンを取得し、valueがshift_idと一致するものがあればパネルを開く
      const shiftRadios = document.getElementsByName('shiftRadio');
      for(let radioIndex=0; radioIndex<shiftRadios.length; radioIndex++){
        const shiftRadioItem = shiftRadios[radioIndex] as HTMLInputElement;
        if(shiftRadioItem.value == job.jobdetail.shift_id){
          openedShiftPanels.value = [radioIndex];
          break;
        }
      }

      // データ取得が終わったら画面表示
      document.getElementById("job-detail-container")!.style.visibility = "visible";
    };

    const changeRadio = async function (
      work_start_date: string,
      shift_id: string
    ) {
      await job
        .getJobInformationId(
          job.jobdetail.company_id,
          job.jobdetail.common_id,
          work_start_date,
          shift_id
        )
        .then(async() => {
          job_information_id = job.jobdetail.job_information_id;

          // シフト情報取得
          await resolveJobShiftInfo(company_id, job_information_id);
        });
    };

    const ckEntryBtn = async function () {
      var targetDate = new Date(
        state.select_working_date +
          " " +
          state.select_working_start_time +
          ":00"
      );

      // キャンセルペナルティ判定
      state.isPenalty = checkPenalty();

      
      // 現在日付から指定時間以内に勤務開始となる求人は応募できなくする
      var limitDateEntry = new Date();
      limitDateEntry.setHours(
        limitDateEntry.getHours() + user.systeminfo.recruitment_closing_hour
      );
      state.entryBtn =
        limitDateEntry.getTime() <= targetDate.getTime() && !state.isPenalty;

      // キャンセル・承認系も期限によりボタン制御
      var limitDateEntryChange = new Date();
      limitDateEntryChange.setHours(
        limitDateEntryChange.getHours() +
          user.systeminfo.recruitment_enable_change_hour
      );
      state.isRecruitmentChangeEnabled =
        limitDateEntryChange.getTime() <= targetDate.getTime() &&
        !state.isPenalty;
    };
    const changeWorkDate = async function (work_date: string) {
      state.select_working_date = work_date;
      ckEntryBtn();
    };
    const changeWorkStartTime = async function (start_time: string) {
      state.select_working_start_time = start_time;
      ckEntryBtn();
    };
    const checkPenalty = function () {
      return user.penaltyPoint >= SYSTEM_OUTAGE_PENALTY_POINT;
    };

    const radioAccordion = (e: any) => {
      e.cancelBubble = true;
    }
    // 採用済み求人があるかチェック
    const checkSameDateAdoption = function () {
      state.isSameDateAdoption = user.sameDateAdoptionFlg === 1;
      return state.isSameDateAdoption
    };

    // チャット画面指定なら切り替える
    let tabIndex = 0;
    if(context.root.$route.hash == '#chat'){
      tabIndex = 2;
    }

    return {
      param_shift,
      radioAccordion,
      isMobile,
      rating,
      myrating,
      jobdetail,
      company_id,
      job_information_id,
      postApplication,
      patchApplication,
      applicationInfo,
      systeminfo,
      attendance,
      favoritelist,
      favoriteIds,
      postFavorite,
      deleteFavorite,
      changeRadio,
      state,
      changeWorkDate,
      changeWorkStartTime,
      jobPostTag,
      login_status,
      execPostAppcation,
      tab: tabIndex,
      openedShiftPanels,
      searchCompanyJob
    };
  },

  components: {
    ReqiredLogin,
    Button,
    SearchHeaderText,
    SubTitle,
    Spacer,
    Attendance,
    Chat,
    RatingList,
    MyRating,
    ApplicationHistory,
    ApplicationCheck,
    FavoriteBtn,
    FavoriteBtnGray,
    EncourageRegistrationDialog,
    EvaluationDialog,
  },
});
