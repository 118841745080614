









































import {
  createComponent,
  computed,
  inject,
  onMounted,
reactive,
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SearchHeaderText from "@/components/atoms/text/SearchHeaderText.vue";
import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const locale_id = computed(() => search.locale.id);
    const locates = computed(() => search.kantoList);

    // 選択したら、storeにcodeとnameを保存
    const decideLocale = (selection: Number[]) => {
      search.decideLocale(selection);
    }

    // 枠の領域クリック時
    const decideLocaleForFlame = (id: number) => {
      const selectedLocaleIdList = search.getSelectedLocaleIdList();
      let selection = selectedLocaleIdList;

      if (selection.includes(id)) {
        selection = selection.filter((select_id) => {
          return select_id !== id;
        });
      } else {
        selection.push(id);
      }

      search.decideLocale(selection);
    }

    // 親のテキスト領域選択時
    const clickParent = (children: any) => {
      const selectedLocaleIdList = search.getSelectedLocaleIdList();
      let selection = selectedLocaleIdList;

      if (selection.includes(children[0].id)) {
        for (let child of children) {
          selection = selection.filter((selected_id) => {
            return selected_id !== child.id;
          });
        }
      } else {
        for (let child of children) {
          selection.push(child.id);
        }
      }
      search.decideLocale(selection);
    }

    const notSelectLocale = () => {
      search.notSelectLocale();
    };

    return {
      locale_id,
      decideLocale,
      decideLocaleForFlame,
      clickParent,
      notSelectLocale,
      locates,
    };
  },

  components: {
    Button,
    SearchHeaderText,
  },
});
