



import { createComponent } from "@vue/composition-api";

import CompanySvg from "@/assets/svg/company.svg";

export default createComponent({
  components: {
    CompanySvg,
  },
});
