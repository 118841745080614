















import { createComponent, inject, onMounted } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const leaveSearching = displayui.leaveSearching;
    const leaveIsDetail = displayui.leaveIsDetail;
    const leaveDrawer = displayui.leaveDrawer;
    onMounted(() => {
      leaveSearching();
      leaveIsDetail();
      leaveDrawer();
    });
  },
  components: {
    Button,
  },
});
