var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"button",class:[
      _vm.btnStyle ? 'button--' + _vm.btnStyle : '',
      {
        'button--outline': _vm.outline,
        'button--round': _vm.round,
        'button--full': _vm.full,
        'button--flat': _vm.flat,
        'is-disabled': _vm.disabled,
      } ],style:(_vm.styleObject),attrs:{"value":_vm.value,"disabled":_vm.disabled,"type":"button"},on:{"click":_vm.click}},[(_vm.icon === 'search')?_c('div',{class:['icon', 'icon-' + _vm.icon]},[_c('SearchIcon',{staticStyle:{"fill":"currentColor"}})],1):_vm._e(),(_vm.icon === 'close')?_c('div',{class:['icon', 'icon-' + _vm.icon]},[_c('CloseIcon',{staticStyle:{"fill":"currentColor"}})],1):_vm._e(),(_vm.icon === 'xmark')?_c('div',{class:['icon', 'icon-' + _vm.icon]},[_c('XmarkIcon',{staticStyle:{"fill":"currentColor"}})],1):_vm._e(),(_vm.icon === 'return')?_c('div',{class:['icon', 'icon-' + _vm.icon]},[_c('ReturnIcon',{staticStyle:{"fill":"currentColor"}})],1):_vm._e(),(_vm.icon === 'eraser')?_c('div',{class:['icon', 'icon-' + _vm.icon]},[_c('EraserIcon',{staticStyle:{"fill":"currentColor"}})],1):_vm._e(),_c('div',{staticClass:"slot-text"},[(_vm.btnStyle !== 'oval')?_vm._t("default"):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }