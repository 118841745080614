export const SALARY = {
  S1000: "1000",
  S1100: "1100",
  S1200: "1200",
  S1300: "1300",
  S1400: "1400",
  S1500: "1500",
  S1600: "1600",
  S1700: "1700",
  S1800: "1800",
  S1900: "1900",
  S2000: "2000",
  S3000: "3000",
  S4000: "4000",
  S5000: "5000",
  S6000: "6000",
  S7000: "7000",
  S8000: "8000",
  S9000: "9000",
  S10000: "10000",
} as const;

export const STARTENDTIME = {
  T0000: {
    hour: "0",
    disp: "0:00",
    cat: "1",
  },
  T0100: {
    hour: "1",
    disp: "1:00",
    cat: "1",
  },
  T0200: {
    hour: "2",
    disp: "2:00",
    cat: "1",
  },
  T0300: {
    hour: "3",
    disp: "3:00",
    cat: "1",
  },
  T0400: {
    hour: "4",
    disp: "4:00",
    cat: "1",
  },
  T0500: {
    hour: "5",
    disp: "5:00",
    cat: "2",
  },
  T0600: {
    hour: "6",
    disp: "6:00",
    cat: "2",
  },
  T0700: {
    hour: "7",
    disp: "7:00",
    cat: "2",
  },
  T0800: {
    hour: "8",
    disp: "8:00",
    cat: "2",
  },
  T0900: {
    hour: "9",
    disp: "9:00",
    cat: "2",
  },
  T1000: {
    hour: "10",
    disp: "10:00",
    cat: "3",
  },
  T1100: {
    hour: "11",
    disp: "11:00",
    cat: "3",
  },
  T1200: {
    hour: "12",
    disp: "12:00",
    cat: "3",
  },
  T1300: {
    hour: "13",
    disp: "13:00",
    cat: "3",
  },
  T1400: {
    hour: "14",
    disp: "14:00",
    cat: "3",
  },
  T1500: {
    hour: "15",
    disp: "15:00",
    cat: "3",
  },
  T1600: {
    hour: "16",
    disp: "16:00",
    cat: "3",
  },
  T1700: {
    hour: "17",
    disp: "17:00",
    cat: "4",
  },
  T1800: {
    hour: "18",
    disp: "18:00",
    cat: "4",
  },
  T1900: {
    hour: "19",
    disp: "19:00",
    cat: "4",
  },
  T2000: {
    hour: "20",
    disp: "20:00",
    cat: "4",
  },
  T2100: {
    hour: "21",
    disp: "21:00",
    cat: "4",
  },
  T2200: {
    hour: "22",
    disp: "22:00",
    cat: "4",
  },
  T2300: {
    hour: "23",
    disp: "23:00",
    cat: "1",
  },
} as const;

export const CALENDER = {
  days: ["日", "月", "火", "水", "木", "金", "土"],
  months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
} as const;

export const BANK_KANA = {
  letter001: {
    name: "あ行",
    contains: "ｱｲｳｴｵ",
    name_list: [
      { name: "あ", contains: "ｱ" },
      { name: "い", contains: "ｲ" },
      { name: "う", contains: "ｳ" },
      { name: "え", contains: "ｴ" },
      { name: "お", contains: "ｵ" },
    ],
  },
  letter002: {
    name: "か行",
    contains: "ｶｷｸｹｺ",
    name_list: [
      { name: "か", contains: "ｶ" },
      { name: "き", contains: "ｷ" },
      { name: "く", contains: "ｸ" },
      { name: "け", contains: "ｹ" },
      { name: "こ", contains: "ｺ" },
    ],
  },
  letter003: {
    name: "さ行",
    contains: "ｻｼｽｾｿ",
    name_list: [
      { name: "さ", contains: "ｻ" },
      { name: "し", contains: "ｼ" },
      { name: "す", contains: "ｽ" },
      { name: "せ", contains: "ｾ" },
      { name: "そ", contains: "ｿ" },
    ],
  },
  letter004: {
    name: "た行",
    contains: "ﾀﾁﾂﾃﾄ",
    name_list: [
      { name: "た", contains: "ﾀ" },
      { name: "ち", contains: "ﾁ" },
      { name: "つ", contains: "ﾂ" },
      { name: "て", contains: "ﾃ" },
      { name: "と", contains: "ﾄ" },
    ],
  },
  letter005: {
    name: "な行",
    contains: "ﾅﾆﾇﾈﾉ",
    name_list: [
      { name: "な", contains: "ﾅ" },
      { name: "に", contains: "ﾆ" },
      { name: "ぬ", contains: "ﾇ" },
      { name: "ね", contains: "ﾈ" },
      { name: "の", contains: "ﾉ" },
    ],
  },
  letter006: {
    name: "は行",
    contains: "ﾊﾋﾌﾍﾎ",
    name_list: [
      { name: "は", contains: "ﾊ" },
      { name: "ひ", contains: "ﾋ" },
      { name: "ふ", contains: "ﾌ" },
      { name: "へ", contains: "ﾍ" },
      { name: "ほ", contains: "ﾎ" },
    ],
  },
  letter007: {
    name: "ま行",
    contains: "ﾏﾐﾑﾒﾓ",
    name_list: [
      { name: "ま", contains: "ﾏ" },
      { name: "み", contains: "ﾐ" },
      { name: "む", contains: "ﾑ" },
      { name: "め", contains: "ﾒ" },
      { name: "も", contains: "ﾓ" },
    ],
  },
  letter008: {
    name: "や行",
    contains: "ﾔﾕﾖ",
    name_list: [
      { name: "や", contains: "ﾔ" },
      { name: "ゆ", contains: "ﾕ" },
      { name: "よ", contains: "ﾖ" },
    ],
  },
  letter009: {
    name: "ら行",
    contains: "ﾗﾘﾙﾚﾛ",
    name_list: [
      { name: "ら", contains: "ﾗ" },
      { name: "り", contains: "ﾘ" },
      { name: "る", contains: "ﾙ" },
      { name: "れ", contains: "ﾚ" },
      { name: "ろ", contains: "ﾛ" },
    ],
  },
  letter010: {
    name: "わ行",
    contains: "ﾜｦﾝ",
    name_list: [
      { name: "わ", contains: "ﾜ" },
      { name: "を", contains: "ｦ" },
      { name: "ん", contains: "ﾝ" },
    ],
  },
  letter011: {
    name: "英字",
    contains: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    name_list: [],
  },
} as const;

export const MONTH = [
  { value: 1, stvalue: "01" },
  { value: 2, stvalue: "02" },
  { value: 3, stvalue: "03" },
  { value: 4, stvalue: "04" },
  { value: 5, stvalue: "05" },
  { value: 6, stvalue: "06" },
  { value: 7, stvalue: "07" },
  { value: 8, stvalue: "08" },
  { value: 9, stvalue: "09" },
  { value: 10, stvalue: "10" },
  { value: 11, stvalue: "11" },
  { value: 12, stvalue: "12" },
] as const;
