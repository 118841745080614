



import { createComponent } from "@vue/composition-api";

import UserSvg from "@/assets/svg/user.svg";

export default createComponent({
  components: {
    UserSvg,
  },
});
