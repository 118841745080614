


























import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import Button from "@/components/atoms/button/Button.vue";
import SearchHeaderText from "@/components/atoms/text/SearchHeaderText.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    onMounted(() => {
      displayui.leaveSearching();
      displayui.leaveDrawer();
      displayui.leaveHeaderSub();
      displayui.leaveHeaderMyPage();
      card_number;
    });

    const card_number = computed(() => user.customerInfo.card_number);

    const submitLogout = () => {
      user.logout();
    };

    return {
      submitLogout,
      card_number,
      // password
    };
  },
  name: "logout",
  components: {
    Button,
    SearchHeaderText,
  },
});
