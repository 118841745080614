



import { createComponent } from "@vue/composition-api";

import XmarkSvg from "@/assets/svg/xmark.svg";

export default createComponent({
  components: {
    XmarkSvg,
  },
});
