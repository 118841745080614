





import {
  createComponent,
  // computed,
  // inject,
  // onMounted,
} from "@vue/composition-api";

export default createComponent({
  props: {},

  // setup() {},
});
