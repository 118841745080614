



























































































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import UserKey from "@/components/user-key";
import DisplayUIKey from "@/components/display-ui-key";

import Button from "@/components/atoms/button/Button.vue";
import SelectBankHeaderText from "@/components/atoms/text/SelectBankHeaderText.vue";

export default createComponent({
  setup() {
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const bank = user.bank;
    const akasatana = user.akasatana;
    const akasatanaDetail = computed(() => user.akasatanaDetail);
    const bankjson = user.bankjson;
    const banklist = computed(() => user.banklist);

    const netbanklist = computed(() => user.netbanklist);
    const citybanklist = computed(() => user.citybanklist);
    const refinedlist = computed(() => user.refinedlist);
    const refinedLetter = computed(() => user.refinedLetter);

    onMounted(() => {
      bankjson();
      user.setAkasatanaDetail([]);
    });

    const decideFirstLetter = (code: string) => {
      user.decideFirstLetter(code);
    };
    const decideBank = (code: string, name: string) => {
      user.decideBank(code, name);
      displayui.changeBankComponent("SelectBankBranch");
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const setAkasatanaDetail = (list: [{}]) => {
      user.setAkasatanaDetail(list);
    };

    return {
      bankjson,
      banklist,
      decideFirstLetter,
      decideBank,
      akasatana,
      akasatanaDetail,
      netbanklist,
      citybanklist,
      refinedlist,
      refinedLetter,
      setAkasatanaDetail,
      bank,
    };
  },

  components: {
    Button,
    SelectBankHeaderText,
  },
});
