import { reactive } from "@vue/composition-api";
import router from "@/router";
// import errorHandle from "@/lib/errorHandle.ts";

export default function ekycDisplayUIStore() {
  // errorHandle();
  const state = reactive({
    loading: false,
    imageToggle: true,
    confirmDialogFlag: false,
    photoDialogFlag: false,
    dragDropItemList: [{}],
    successSnackBar: {
      display: false,
      message: "",
    },
    errorSnackBar: {
      display: false,
      message: "",
    },
    listItemPerPage: 10,
    listFooterProps: {
      "items-per-page-options": [10, 20, 50, 100, -1],
    },
  });

  return {
    get confirmDialogFlag() {
      return state.confirmDialogFlag;
    },
    get photoDialogFlag() {
      return state.confirmDialogFlag;
    },
    get dragDropItemList() {
      return state.dragDropItemList;
    },
    get successSnackBar() {
      return state.successSnackBar;
    },
    get errorSnackBar() {
      return state.errorSnackBar;
    },
    get listItemPerPage() {
      return state.listItemPerPage;
    },
    get listFooterProps() {
      return state.listFooterProps;
    },
    syncSleep(milliSeconds: any) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, milliSeconds);
      });
    },
    showSuccessSnackBar(message: string) {
      state.successSnackBar = {
        display: true,
        message: message,
      };
    },
    showErrorSnackBar(message: string) {
      state.errorSnackBar = {
        display: true,
        message: message,
      };
    },
    openConfirmDialog() {
      state.confirmDialogFlag = true;
    },
    openPhotoDialog() {
      state.confirmDialogFlag = true;
    },
    closeConfirmDialog() {
      state.confirmDialogFlag = false;
    },
    closePhotoDialog() {
      state.confirmDialogFlag = false;
    },
    openOverlayLoading() {
      state.loading = true;
    },
    closeOverlayLoading() {
      state.loading = false;
    },
    updateDragDropItemList(e: Array<object>) {
      state.dragDropItemList = e;
    },
    setListItemPerPage(e: number) {
      state.listItemPerPage = e;
    },
    clearDragDropItemList() {
      state.dragDropItemList = [];
    },
    clearErrorSnackBar() {
      state.errorSnackBar = {
        display: false,
        message: "",
      };
    },
    clearSuccessSnackBar() {
      state.successSnackBar = {
        display: false,
        message: "",
      };
    },
  };
}
export type EkycDisplayUIStore = ReturnType<typeof ekycDisplayUIStore>;
