



import { createComponent } from "@vue/composition-api";

import EraserSvg from "@/assets/svg/eraser.svg";

export default createComponent({
  components: {
    EraserSvg,
  },
});
