








import { createComponent, computed, inject } from "@vue/composition-api";
import FavoriteOutlineIcon from "@/components/atoms/icon/FavoriteOutlineIcon.vue";

import DisplayUIKey from "@/components/display-ui-key";

export default createComponent({
  components: {
    FavoriteOutlineIcon,
  },
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const searching = computed(() => displayui.searching);

    return {
      searching,
    };
  },
});
