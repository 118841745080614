






import { createComponent } from "@vue/composition-api";
import NoticeListView from "@/components/templates/NoticeListView.vue";

export default createComponent({
  components: {
    NoticeListView,
  },
});
