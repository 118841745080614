





























import {
  createComponent,
  inject,
  onMounted,
  computed,
  } from "@vue/composition-api";

import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import DoRating from "@/components/molecules/DoRating.vue";
export default createComponent({
  props: {
    //dialogの表示非表示
    dialog: {
      type: Boolean,
      required: true
    },
    job_information: {
      type: Object
    }
  },
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
     const parentCancel = ()=>{
      context.emit('parentCancel');
    }
    return {
      parentCancel
    };
  },
  components: {
    DoRating
  }
});
