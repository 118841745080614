





























import {
  createComponent,
  provide,
  inject,
  computed,
  ref,
  onMounted,
  // computed,
  reactive
} from "@vue/composition-api";

import HeaderMain from "@/components/molecules/HeaderMain.vue";
import HeaderSub from "@/components/molecules/HeaderSub.vue";
import PageFooter from "@/components/molecules/PageFooter.vue";
// import HeaderMyPage from "@/components/molecules/HeaderMyPage.vue";

import Button from "@/components/atoms/button/Button.vue";
// import UserInfo from "@/components/molecules/UserInfo.vue";

import DisplayUIKey from "@/components/display-ui-key";
import displayUIStore from "@/stores/displayui";
import SearchKey from "@/components/search-key";
import searchStore from "@/stores/search";
import JobKey from "@/components/job-key";
import jobStore from "@/stores/job";
import UserKey from "@/components/user-key";
import userStore from "@/stores/user";

import AuthinfoKey from "@/components/authinfo-key";
import authinfoStore from "@/stores/authinfo";

import ProvisionalKey from "@/components/provisional-key";
import provisionalStore from "@/stores/provisional";
import RegistrationKey from "@/components/registration-key";
import registrationStore from "@/stores/registration";
import EkycDisplayUIKey from "@/components/ekyc-display-ui-key";
import ekycDisplayUIStore from "@/stores/ekyc-displayui";
import CloseAccountKey from "@/components/close-account-key";
import closeAccountStore from "@/stores/close-account";

import Drawer from "@/components/templates/Drawer.vue";

import Vue from "vue";
import router from "@/router";
import EvaluationModal from "@/components/templates/EvaluationModal.vue";

const history = window.history
const initState = history.state
switch (initState && initState.canGoBack) {
  // 初回アクセス
  case null:
  case undefined:
    const stateCopy = initState === null ? {} : Object.assign({}, initState)
    stateCopy.canGoBack = false
    history.replaceState(stateCopy, '')
}

const routeData = Vue.observable({
  mode: "",
  card_number: "",
  token_pass: "",
  token: "",
  refresh: "",
  agreement_flg: "",
});
router.afterEach((route) => {
  routeData.mode = route.query.mode as string;
  routeData.card_number = route.query.card_number as string;
  routeData.token_pass = route.query.token_pass as string;
  routeData.token = route.query.token as string;
  routeData.refresh = route.query.refresh as string;
  routeData.agreement_flg = route.query.agreement_flg as string;

  const state = history.state

  // <nuxt-link>, $router.push() 等による遷移
  if (state.canGoBack === undefined) {
    const stateCopy = Object.assign({}, state)
    stateCopy.canGoBack = true
    history.replaceState(stateCopy, '')
  }
});
function getQuery() {
  return routeData;
}

export default createComponent({
  setup(context) {
    provide(DisplayUIKey, displayUIStore());
    provide(SearchKey, searchStore());
    provide(JobKey, jobStore());
    provide(UserKey, userStore());
    provide(AuthinfoKey, authinfoStore());
    provide(ProvisionalKey, provisionalStore());
    provide(RegistrationKey, registrationStore());
    provide(EkycDisplayUIKey, ekycDisplayUIStore());
    provide(CloseAccountKey, closeAccountStore());

    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }

    const forWeb = process.env.VUE_APP_FOR_WEB;

    const drawer = computed(() => displayui.drawer);

    const smbnlink_closed_period = 1000 * 60 * 60 * 24 * 30; // 保存期間:30日(1000ミリ秒 * 60秒 * 60分 * 24時間 * 30日)
    const smbnlink_closed_key = "smbnlink_closed";
    const smbnlink_closed_time_key = "smbnlink_closed_time";

    // バナー表示フラグのタイムアウト処理
    const smbnlink_closed_val = localStorage.getItem(smbnlink_closed_key)
    const smbnlink_closed_time_val = localStorage.getItem(smbnlink_closed_time_key)
    if(smbnlink_closed_val !== null) {
      let closed_clear_flg = false;
      if(smbnlink_closed_time_val !== null) {
        // 保存されている値と現在の値の差を見て、期間を過ぎていないか確認
        var df = Date.now() - parseFloat(smbnlink_closed_time_val);
        // 期間を過ぎている場合はlocalStorageの値を削除
        if(df > smbnlink_closed_period) {
          closed_clear_flg = true;
        }
      }
      // 時間が格納されていなければすぐクリア
      else {
        closed_clear_flg = true;
      }
      if(closed_clear_flg) {
        localStorage.removeItem(smbnlink_closed_key);
        localStorage.removeItem(smbnlink_closed_time_key);
      }
    }

    // Androidならバナーを表示
    let isShowSmBnLink = false;
    if ((navigator.userAgent.indexOf('Android') != -1) && navigator.userAgent.indexOf('Yappli') == -1) {
      const smbnlinkClosed = localStorage.getItem(smbnlink_closed_key);

      // バナーを閉じた情報が残っていなければ
      if(smbnlinkClosed != '1'){
        // バナーを表示する
        isShowSmBnLink = true;

        // バナー表示分、上側にスペースを空ける
        document.getElementsByTagName('html')[0].style.paddingTop = "85px";
      }
    }

    // バナーを閉じる
    const closeSmBnLink = function(){
      document.getElementById("SmBnLink")!.style.display = "none";

      // バナー表示用のスペースを除去
      document.getElementsByTagName('html')[0].style.paddingTop = "0px";

      // バナーを閉じた情報を保存
      localStorage.setItem(smbnlink_closed_key, "1");
      localStorage.setItem(smbnlink_closed_time_key, Date.now().toString());

      return false;
    };

    if (forWeb === "false") {
      const query = getQuery();
      const mode = query.mode;
      var tokenstr = localStorage.getItem("token");
      switch (mode) {
        case "login":
          if (tokenstr) {
          } else {
            localStorage.setItem("token", query.token);
            localStorage.setItem("refresh", query.refresh);
            localStorage.setItem("agreement_flg", query.agreement_flg);
          }
          break;
        case "my-page":
          if (tokenstr) {
            router.push("/my-page");
          } else {
            router.push("/");
          }
          break;
        case "logout":
          router.push("/logout");
          break;
        default:
          break;
      }
    }

    // コンポーネント初期化時
    onMounted(async () => {
      await init();
    });

    // ルート遷移時
    router.afterEach(async (to: any, from: any) => {
      await init();
    });

    // 画面初期処理(※全画面共通で行われる)
    const init = async function(){
      const currentPath = router.currentRoute.path;

      // 画面編集中解除
      displayui.setEditing(false);

      // システム情報取得(一旦保留)
      //await search.requestSystemInfo();

      // ログイン中でなければ処理しない
      if (!user.login_status) {
        return;
      }

      // 会員情報を取得
      await user.requestCustomerInfo();

      // 退会している場合
      if(user.checkAccountClosed()){
        user.logoutAuto();
        return;
      }

      // 処理スキップ対象でなければ処理
      const arySkipPage = ['/terms/agree/', '/logout', '/after-logout'];
      const isSkipPage = (arySkipPage.indexOf(currentPath) !== -1);
      if (!isSkipPage) {
        // 規約同意していなければ規約同意画面に遷移
        if(!user.checkAgreement()){
          user.gotoTermsAgreePage();
        }

        // 未評価案件チェック・表示
        // 規約ページの場合はポップアップを表示しない
        // 同期処理にすると通信待ちで全画面の後続処理に影響するため非同期呼び出し
        job.checkUnrated();
      }
    };

    return {
      displayui,
      user,
      job,
      forWeb,
      isShowSmBnLink,
      closeSmBnLink,
      drawer
    };
  },

  components: {
    HeaderMain,
    HeaderSub,
    PageFooter,
    // HeaderMyPage,
    Button,
    EvaluationModal,
    Drawer
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler(e: any) {
      // 右クリック禁止制御は翻訳機能使用のため解除
    },
  },
});
