









import { createComponent, computed, inject } from "@vue/composition-api";
import SearchIcon from "@/components/atoms/icon/SearchIcon.vue";

import DisplayUIKey from "@/components/display-ui-key";

export default createComponent({
  components: {
    SearchIcon,
  },
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const searching = computed(() => displayui.searching);
    const toggleModal = () => {
      displayui.leaveDrawer();
      if(displayui.toggleSearching()) {
        document.getElementById("body")!.style.display = "none";
        document.getElementById("page-footer")!.style.display = "none";
        document.getElementById("inputinner")!.style.height = "100vh";
      } else {
        displayui.leaveSearchingClose();
      }
    }
    const changeSearchComponent = displayui.changeSearchComponent;
    changeSearchComponent("SearchInputBoxMain");


    return {
      searching,
      toggleModal,
      changeSearchComponent,
    };
  },
});
