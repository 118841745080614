






























import {
  createComponent,
  inject,
  computed,
  onMounted,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";

import JobcategoryLabel from "@/components/atoms/text/JobcategoryLabel.vue";
import DateBox from "@/components/molecules/DateBox.vue";
import ArrowRightIconC from "@/components/atoms/icon/ArrowRightIconC.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const jobdetail = computed(() => job.jobdetail);
    const company_id = job.jobdetail.company_id;
    const job_information_id = job.jobdetail.job_information_id;

    const myrating = computed(() => job.myrating);

    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveDrawer = displayui.leaveDrawer;

    onMounted(() => {
      job.getMyRating(company_id, job_information_id);
    });

    return { myrating };
  },
  components: { JobcategoryLabel, DateBox, ArrowRightIconC },
});
