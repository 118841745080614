









import { createComponent, computed, inject } from "@vue/composition-api";
import CloseBtn from "@/components/atoms/button/CloseBtn.vue";

import DisplayUIKey from "@/components/display-ui-key";

export default createComponent({
  components: {
    CloseBtn,
  },
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const changeBankComponent = displayui.changeBankComponent;

    return {
      changeBankComponent,
    };
  },
});
