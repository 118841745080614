









import { createComponent, inject, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";

// import SearchNav from "@/components/molecules/SearchNav.vue";
import SearchInputBox from "@/components/molecules/SearchInputBox.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const searching = computed(() => displayui.searching);
    const toggleSearching = displayui.toggleSearching;

    return {
      searching,
      toggleSearching,
    };
  },
  components: {
    // SearchNav,
    SearchInputBox,
  },
});
