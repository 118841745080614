import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionApi from "@vue/composition-api";
import VueGtag from "vue-gtag";
import Filters from "@/lib/filters";

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);
Vue.use(
  VueGtag,
  {
    config: { id: "GTM-MPPP62T" },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageTrackerTemplate(to: any) {
      return {
        page_path: to.path,
      };
    },
  },
  router
);

Vue.mixin({
  filters: Filters,
});

new Vue({
  router,
  vuetify,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (h: any) => h(App),
}).$mount("#app");
