import { render, staticRenderFns } from "./DateBox.vue?vue&type=template&id=4521110b&scoped=true&"
import script from "./DateBox.vue?vue&type=script&lang=ts&"
export * from "./DateBox.vue?vue&type=script&lang=ts&"
import style0 from "./DateBox.vue?vue&type=style&index=0&id=4521110b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4521110b",
  null
  
)

export default component.exports