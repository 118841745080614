


























































































































import {
  createComponent,
  inject,
  computed,
  onMounted,
  onUnmounted,
reactive,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import JobKey from "@/components/job-key";
import EvaluationDialog from "@/components/molecules/EvaluationDialog.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const applicationHistoryAndStatusCode = computed(() => {
      return user.applicationHistoryAndStatusCode;
    });
    const applicationHistoryDate = computed(() => user.applicationHistoryDate);

    const decideApplicationMonth = (month: any) => {
      user.decideApplicationMonth(month);
    };
  
    const year = new Date().getFullYear()
    const month = String(new Date().getMonth() + 1).padStart(2, "0")
    
    const applicationInfo = computed(() => user.applicationInfo);
    const getApplicationInfo = user.getApplicationInfo;

    const state = reactive({
      applicationHistoryDateIndex: 0
    });

    const prev = () => {
      // 最後の月なら処理をしない
      if (state.applicationHistoryDateIndex === applicationHistoryDate.value.length - 1) return;
      // applicationHistoryDateの配列は降順で並んでいるので、prevではインデックスに+1、nextでは-1
      state.applicationHistoryDateIndex = state.applicationHistoryDateIndex + 1;
      const year_month = applicationHistoryDate.value[state.applicationHistoryDateIndex];
      decideApplicationMonth(year_month);
    }
    const next = () => {
      // 先頭の月なら処理をしない
      if (state.applicationHistoryDateIndex === 0) return;
      state.applicationHistoryDateIndex = state.applicationHistoryDateIndex - 1;
      const year_month = applicationHistoryDate.value[state.applicationHistoryDateIndex];
      decideApplicationMonth(year_month);
    }

    onMounted(async() => {
      await user.getApplicationHistoryDate();

      // applicationHistoryDateの定義と中身がずれているので文字列と結合してstringとする
      let applicationHistoryMonthYmArray:string[] = [];
      for(let dateIndex=0; dateIndex<applicationHistoryDate.value.length; dateIndex++){
        const targetMonthYm:string = applicationHistoryDate.value[dateIndex] + '';
        applicationHistoryMonthYmArray.push(targetMonthYm);
      }

      // 今月勤務の応募があれば今月を表示
      const today = new Date();
      const thisYear = today.getFullYear();
      const thisMonth = today.getMonth() + 1;
      const thisMonthYm:string = thisYear + '/' + ( '00' + thisMonth ).slice( -2 )
      let targetDateIndex = -0;
      let nowDateIndex = -1;
      let futureDateIndex = -1;
      let pastDateIndex = -1;
      let isExistsThisMonth = false;
      // 時系列の降順で格納されている配列を探索
      for(let dateIndex=0; dateIndex<applicationHistoryMonthYmArray.length; dateIndex++) {
        const targetMonthYm:string = applicationHistoryMonthYmArray[dateIndex];
        // 未来月
        if(targetMonthYm > thisMonthYm) {
          // 上書きし続ける(最も今月に近い月を格納)
          futureDateIndex = dateIndex;
        }
        // 過去月
        else if(targetMonthYm < thisMonthYm) {
          // 1回のみ格納(最も今月に近い月を格納)
          if(pastDateIndex == -1) {
            pastDateIndex = dateIndex;
          }
        }
        // 今月
        else {
          nowDateIndex = dateIndex;
          break;
        }

      }

      // 今月勤務の応募がある場合
      if(nowDateIndex != -1) {
        // 今月を表示
        targetDateIndex = nowDateIndex;
      }
      // 今月勤務の応募がなく、過去の応募の勤務月がある場合
      else if(pastDateIndex != -1) {
        // もっとも近い過去の応募の勤務月を表示
        targetDateIndex = pastDateIndex;
      }
      // 過去にない場合
      else if(futureDateIndex != -1) {
        // もっとも近い未来の応募の勤務月を表示
        targetDateIndex = futureDateIndex;
      }
      const targetApplicationMonthYm = applicationHistoryMonthYmArray[targetDateIndex];
      state.applicationHistoryDateIndex = targetDateIndex;
      user.decideApplicationMonth(targetApplicationMonthYm);
    });
    onUnmounted(() => {
      user.applicationHistoryReset();
    });
    // 労働条件通知書出力(PDF)
    const outputPdfWorkingConditionsNotice = (
      job_information_id: number,
      company_id: number
    ) => {
      user.outputPdfWorkingConditionsNotice(job_information_id, company_id);
    };
    return {
      applicationHistoryAndStatusCode,
      applicationHistoryDate,
      decideApplicationMonth,
      applicationInfo,
      outputPdfWorkingConditionsNotice,
      state,
      prev,
      next
    };
  },
  components: {
    EvaluationDialog,
  },
});
