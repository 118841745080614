

















































































































import {
  createComponent,
  inject,
  computed,
  onMounted,
  reactive,
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import JobKey from "@/components/job-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import ReqiredLogin from "@/components/templates/RequiredLogin.vue";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const state = reactive({
      dispMessageDialog: false,
    });

    const joblist = computed(() => job.joblist);
    const jobSortTarget = computed(() => job.jobSortTarget);
    const jobSortOrder = computed(() => job.jobSortOrder);

    const jobjson = job.jobjson;
    const isJobSearchCompleted = computed(() => job.isJobSearchCompleted);

    const leaveSearching = displayui.leaveSearching;
    const leaveDrawer = displayui.leaveDrawer;
    const leaveIsDetail = displayui.leaveIsDetail;

    const login_status = computed(() => user.login_status);
    const imageToggle = computed(() => displayui.imageToggle);
    const getFavoriteJson = user.getFavoriteJson;

    const toggleSearching = displayui.toggleSearching;

    onMounted(() => {
      leaveSearching();
      leaveDrawer();
      leaveIsDetail();
    });

    const favoritelist = computed(() => user.favoritelist);
    const favoriteIds = computed(() => user.favoriteIds);
    const postFavorite = (company_id: string, job_information_id: string) => {
      user.postFavorite(company_id, job_information_id);
    };
    const deleteFavorite = (company_id: string, job_information_id: string) => {
      user.deleteFavorite(company_id, job_information_id);
    };

    const formatMonthDay = (date_yyyymmdd: string) => {
      const date_month = date_yyyymmdd.replace(/(\d{4})\/(\d{2})\/(\d{2})/g, '$2');
      const date_day = date_yyyymmdd.replace(/(\d{4})\/(\d{2})\/(\d{2})/g, '$3');
      return Number(date_month) + '月' + Number(date_day) + '日'
    };

    const searchParams = computed(() => search.searchParams);

    return {
      login_status,
      joblist,
      jobSortTarget,
      jobSortOrder,
      jobjson,
      isJobSearchCompleted,
      postFavorite,
      deleteFavorite,
      favoritelist,
      favoriteIds,
      imageToggle,
      state,
      toggleSearching,
      formatMonthDay
    };
  },

  components: {
    ReqiredLogin,
  },
});
