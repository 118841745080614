

































































import { createComponent } from "@vue/composition-api";
import router from "@/router";

// import MemberKey from "@/components/member-key";
// import WalletKey from "@/components/wallet-key";

export default createComponent({
  setup() {
    // ログイン画面に遷移
    const login = async () => {
      router.push({
         name: "login"
      });
    };

    const gotoJobpayConnect = async () => {
      window.open('/account/connect/jobpay', '_blank');
    };

    return {
      login,
      gotoJobpayConnect
    };
  },
});
