
























import {
  createComponent,
  inject,
  onMounted,
  computed,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import UserInfo from "@/components/molecules/UserInfo.vue";
import CommonConfirmDialog from "@/components/templates/CommonConfirmDialog.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const leaveSearching = displayui.leaveSearching;
    const leaveHeaderSub = displayui.leaveHeaderSub;
    const leaveDrawer = displayui.leaveDrawer;
    const customerInfo = computed(() => user.customerInfo);

    const onHeaderMyPage = displayui.onHeaderMyPage;
    const userInfo = computed(() => user.userinfo);

    // 配信停止確認
    const confirmRecommendUnSubscribed = () => {
      displayui.openDialogCommon("レコメンドメールの配信を停止します。よろしいですか？");
    };
    // 配信停止実行
    const setRecommendUnSubscribed = async () => {
      await user.setRecommendSubscribed(0);
    };

    // 配信再開確認
    const confirmRecommendSubscribed = () => {
      displayui.openDialogCommon("レコメンドメールの配信を再開します。よろしいですか？");
    };
    // 配信再開実行
    const setRecommendSubscribed = async () => {
      await user.setRecommendSubscribed(1);
    };

    onMounted(async () => {
      leaveSearching();
      leaveHeaderSub();
      leaveDrawer();
      onHeaderMyPage();
    });

    return {
      userInfo,
      customerInfo,
      confirmRecommendUnSubscribed,
      setRecommendUnSubscribed,
      confirmRecommendSubscribed,
      setRecommendSubscribed,
    };
  },
  components: {
    UserInfo,
    CommonConfirmDialog,
  },
});
