



























import { createComponent, inject, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";

export default createComponent({
  props: {
    okFunction: {
      type: Function,
    },
  } as const,
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const confirmDialog = computed(() => displayui.dialogCommon);
    const dialogCommonMessage = computed(() => displayui.dialogCommonMessage);

    const clickOkButton = () => {
      displayui.closeDialogCommon();
      if (props.okFunction) {
        props.okFunction();
      }
    };
    const clickCancelButton = () => {
      displayui.closeDialogCommon();
    };
    return {
      confirmDialog,
      dialogCommonMessage,
      clickOkButton,
      clickCancelButton,
    };
  },
  components: {},
});
