



import { createComponent } from "@vue/composition-api";

import RefleshSvg from "@/assets/svg/reflesh.svg";

export default createComponent({
  components: {
    RefleshSvg,
  },
});
