



import { createComponent } from "@vue/composition-api";

import ArrowRightCSvg from "@/assets/svg/arrow_right_c.svg";

export default createComponent({
  components: {
    ArrowRightCSvg,
  },
});
