




















































import {
  createComponent,
  computed,
  inject,
  onMounted,
  ref,
reactive,
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const jobCategoryId = computed(() => search.jobCategory.id);
    const jobcategorylist = computed(() => search.jobcategorylist[0]?.children);
    const changeSearchComponent = displayui.changeSearchComponent;

    const decideNotSelectJobCategory = () => {
      search.decideNotSelectJobCategory();
      changeSearchComponent("SearchInputBoxMain");
    };

    interface jobCategory {
      id: number;
      parent: boolean;
      job_small_code: string;
      job_small_name: string;
      job_small_image: string;
      job_small_explain: string;
      job_small_beginner_flg: boolean;
    }[]

    // 選択したら、storeにid,code,nameを保存
    const decideJobCategory = (selection: Number[]) => {
      search.decideJobCategory(selection);
    }

    // 枠の領域クリック時
    const decideJobCategoryForFlame = (id: number) => {
      const selectedJobCategoryIdList = search.getSelectedJobCategoryIdList();
      let selection = selectedJobCategoryIdList;

      if (selection.includes(id)) {
        selection = selection.filter((select_id) => {
          return select_id !== id;
        });
      } else {
        selection.push(id);
      }
      search.decideJobCategory(selection);
    }

    // 親のテキスト領域選択時
    const clickParent = (children: jobCategory[]) => {
      const selectedJobCategoryIdList = search.getSelectedJobCategoryIdList();
      let selection = selectedJobCategoryIdList;

      if (selection.includes(children[0].id)) {
        for (let child of children) {
          selection = selection.filter((selected_id) => {
            return selected_id !== child.id;
          });
        }
      } else {
        for (let child of children) {
          selection.push(child.id);
        }
      }
      search.decideJobCategory(selection);
    }

    return {
      jobcategorylist,
      decideNotSelectJobCategory,
      jobCategoryId,
      changeSearchComponent,
      decideJobCategory,
      decideJobCategoryForFlame,
      clickParent
    };
  },
});
