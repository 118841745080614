



import { createComponent } from "@vue/composition-api";

import ReturnSvg from "@/assets/svg/return.svg";

export default createComponent({
  components: {
    ReturnSvg,
  },
});
