












































import { createComponent, onMounted, onUnmounted, inject, ref, reactive, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import JobKey from "@/components/job-key";
import UserKey from "@/components/user-key";
import SearchKey from "@/components/search-key";
import ListView from "@/components/templates/ListView.vue";
// import Button from "@/components/atoms/button/Button.vue";

export default createComponent({
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }

    const login_status = computed(() => user.login_status);

    const sortByTabIndex = (index:number) => job.sortByTabIndex(index);
    const searchParams = computed(() => search.searchParams);
  
    const onHeaderSub = displayui.onHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    // キャンペーンバナー設定
    let campaignBannerInfo = reactive({href:"", img_url:""});

    onUnmounted(async () => {
      window.removeEventListener("popstate", handlePopstate);
    });

    onMounted(async () => {
      window.addEventListener("popstate", handlePopstate);
      await init();
    });

    // 進む・戻るボタンで画面遷移
    const handlePopstate = async function(){
      await init();
    }

    // 画面初期処理
    const init = async function(){
      // データ取得が終わるまで画面非表示
      document.getElementById('top-container')!.style.visibility = "hidden";

      // クエリ取得
      const query:any = context.root.$route.query;

      displayui.leaveSearchingClose();

      // システム情報取得
      await search.requestSystemInfo();

      // ログイン中の場合
      if (user.login_status) {
        // ユーザー情報取得
        await user.requestCustomerInfo();
        // 規約同意していなければ規約同意画面に遷移
        if(!user.checkAgreement()){
          user.gotoTermsAgreePage();
        }
      }

      // キャンペーン情報設定
      const campaignInfo:any = search.campaignInfo;
      if(campaignInfo){
        if(isCampaignDate(campaignInfo['start_ymd'], campaignInfo['end_ymd'])){
          campaignBannerInfo.href = campaignInfo['link_url'];
          campaignBannerInfo.img_url = `/img/campaign/${campaignInfo['banner_image']}`;
        }
      }
      
      // URLから検索条件を取得して検索
      search.loadJobSearchFromQuery(query);
      search.collectData();
      await job.searchjobjson(searchParams);

      onHeaderSub();
      leaveHeaderMyPage();

      // 直前に表示していた求人までスクロール
      scrollToJob(job.getViewJobKey());
      job.clearViewJobKey();

      // データ取得が終わったら画面表示
      document.getElementById('top-container')!.style.visibility = "visible";
    };

    function isCampaignDate(fromYmd:string, toYmd:string){
      let nowDate = new Date();

      let fromDate = null;
      if(fromYmd != ''){
        let fromYmdArray = fromYmd.split("/");
        let fromYear:number = parseInt(fromYmdArray[0],10);
        let fromMonth:number = parseInt(fromYmdArray[1],10);
        let fromDay:number = parseInt(fromYmdArray[2],10);
        fromDate = new Date(fromYear, fromMonth - 1, fromDay);
      }

      let toDate = null;
      if(toYmd != ''){
        let toYmdArray = toYmd.split("/");
        let toYear:number = parseInt(toYmdArray[0],10);
        let toMonth:number = parseInt(toYmdArray[1],10);
        let toDay:number = parseInt(toYmdArray[2],10);
        toDate = new Date(toYear, toMonth - 1, toDay, 23, 59, 59);
      }

      if(fromDate == null || fromDate <= nowDate){
        if(toDate == null || nowDate <= toDate){
          return true;
        }
      }
      return false;
    }

    // 指定求人までスクロール
    function scrollToJob(jobKey:string) {
      try {
        const jobTopElem = document.getElementById('job-list-top');
        if(!jobTopElem) return;
        const jobElems = jobTopElem.querySelectorAll('[data-job-key="'+jobKey+'"]');
        if(!jobElems) return;
        const jobElem = jobElems[0];
        if(!jobElem) return;

        const targetDOMRect = jobElem.getBoundingClientRect();
        const targetTop = targetDOMRect.top + window.pageYOffset - 50;
        window.scrollTo({
          top: targetTop,
          behavior: 'auto'
        });
      } catch(e){}
    }
    (window as any).scrollToJob = scrollToJob;

    return {
      job,
      sortByTabIndex,
      login_status,
      campaignBannerInfo
    };
  },

  components: {
    ListView
    // EvaluationModal
  },
});
